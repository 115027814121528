import React, { useEffect } from "react";
import { useState } from "react";
import { Table } from "react-bootstrap";
import { isNil } from 'lodash';
import initNetwork from "../initNetwork.json";

export default function EVSE_VPP_Broadcast(props) {
    const [state, setState] = useState(
        {
            ACEVSE5: {
                price_Grid_after: initNetwork[JSON.parse(sessionStorage.dateTime).value].bus_data.lam_p['5'].toFixed(3),
                price_G2V_after: '-',
                price_V2G_after: '-',
                price_VPP_after: '-'
            },
            ACEVSE7: {
                price_Grid_after: initNetwork[JSON.parse(sessionStorage.dateTime).value].bus_data.lam_p['7'].toFixed(3),
                price_G2V_after: '-',
                price_V2G_after: '-',
                price_VPP_after: '-'
            },
            ACEVSE13: {
                price_Grid_after: initNetwork[JSON.parse(sessionStorage.dateTime).value].bus_data.lam_p['13'].toFixed(3),
                price_G2V_after: '-',
                price_V2G_after: '-',
                price_VPP_after: '-'
            },
            DCEVSE3: {
                price_Grid_after: initNetwork[JSON.parse(sessionStorage.dateTime).value].bus_data.lam_p['3'].toFixed(3),
                price_G2V_after: '-',
                price_V2G_after: '-',
                price_VPP_after: '-'
            },
            DCEVSE9: {
                price_Grid_after: initNetwork[JSON.parse(sessionStorage.dateTime).value].bus_data.lam_p['9'].toFixed(3),
                price_G2V_after: '-',
                price_V2G_after: '-',
                price_VPP_after: '-'
            },
            DCEVSE14: {
                price_Grid_after: initNetwork[JSON.parse(sessionStorage.dateTime).value].bus_data.lam_p['14'].toFixed(3),
                price_G2V_after: '-',
                price_V2G_after: '-',
                price_VPP_after: '-'
            },
            DR_Com1: {
                price_Grid_after: initNetwork[JSON.parse(sessionStorage.dateTime).value].bus_data.lam_p['1'].toFixed(3),
                price_G2V_after: '-',
                price_V2G_after: '-',
                price_VPP_after: '-'
            },
            DR_Ind12: {
                price_Grid_after: initNetwork[JSON.parse(sessionStorage.dateTime).value].bus_data.lam_p['12'].toFixed(3),
                price_G2V_after: '-',
                price_V2G_after: '-',
                price_VPP_after: '-'
            },
            DR_Res4: {
                price_Grid_after: initNetwork[JSON.parse(sessionStorage.dateTime).value].bus_data.lam_p['4'].toFixed(3),
                price_G2V_after: '-',
                price_V2G_after: '-',
                price_VPP_after: '-'
            },
            DR_Res6: {
                price_Grid_after: initNetwork[JSON.parse(sessionStorage.dateTime).value].bus_data.lam_p['6'].toFixed(3),
                price_G2V_after: '-',
                price_V2G_after: '-',
                price_VPP_after: '-'
            },
            DR_Res8: {
                price_Grid_after: initNetwork[JSON.parse(sessionStorage.dateTime).value].bus_data.lam_p['8'].toFixed(3),
                price_G2V_after: '-',
                price_V2G_after: '-',
                price_VPP_after: '-'
            }
        }
    );

    useEffect(() => {
        if (isNil(props.data)) return;

        const evse_ev = props.data['evse_ev_broadcast'];
        const vpp = props.data['vpp_broadcast'];

        setState(
            {
                ACEVSE5: {
                    price_Grid_after: evse_ev['ACEVSE 5'].price_Grid_after.toFixed(3),
                    price_G2V_after: evse_ev['ACEVSE 5'].price_G2V_after.toFixed(3),
                    price_V2G_after: '-',
                    price_VPP_after: '-'
                },
                ACEVSE7: {
                    price_Grid_after: evse_ev['ACEVSE 7'].price_Grid_after.toFixed(3),
                    price_G2V_after: evse_ev['ACEVSE 7'].price_G2V_after.toFixed(3),
                    price_V2G_after: '-',
                    price_VPP_after: '-'
                },
                ACEVSE13: {
                    price_Grid_after: evse_ev['ACEVSE 13'].price_Grid_after.toFixed(3),
                    price_G2V_after: evse_ev['ACEVSE 13'].price_G2V_after.toFixed(3),
                    price_V2G_after: '-',
                    price_VPP_after: '-'
                },
                DCEVSE3: {
                    price_Grid_after: evse_ev['DCEVSE 3'].price_Grid_after.toFixed(3),
                    price_G2V_after: evse_ev['DCEVSE 3'].price_G2V_after.toFixed(3),
                    price_V2G_after: evse_ev['DCEVSE 3'].price_V2G_after.toFixed(3),
                    price_VPP_after: '-'
                },
                DCEVSE9: {
                    price_Grid_after: evse_ev['DCEVSE 9'].price_Grid_after.toFixed(3),
                    price_G2V_after: evse_ev['DCEVSE 9'].price_G2V_after.toFixed(3),
                    price_V2G_after: evse_ev['DCEVSE 9'].price_V2G_after.toFixed(3),
                    price_VPP_after: '-'
                },
                DCEVSE14: {
                    price_Grid_after: evse_ev['DCEVSE 14'].price_Grid_after.toFixed(3),
                    price_G2V_after: evse_ev['DCEVSE 14'].price_G2V_after.toFixed(3),
                    price_V2G_after: evse_ev['DCEVSE 14'].price_V2G_after.toFixed(3),
                    price_VPP_after: '-'
                },
                DR_Com1: {
                    price_Grid_after: vpp['DR_Com 1'].price_Grid_after.toFixed(3),
                    price_G2V_after: '-',
                    price_V2G_after: '-',
                    price_VPP_after: vpp['DR_Com 1'].price_VPP_after.toFixed(3)
                },
                DR_Ind12: {
                    price_Grid_after: vpp['DR_Ind 12'].price_Grid_after.toFixed(3),
                    price_G2V_after: '-',
                    price_V2G_after: '-',
                    price_VPP_after: vpp['DR_Ind 12'].price_VPP_after.toFixed(3)
                },
                DR_Res4: {
                    price_Grid_after: vpp['DR_Res 4'].price_Grid_after.toFixed(3),
                    price_G2V_after: '-',
                    price_V2G_after: '-',
                    price_VPP_after: vpp['DR_Res 4'].price_VPP_after.toFixed(3)
                },
                DR_Res6: {
                    price_Grid_after: vpp['DR_Res 6'].price_Grid_after.toFixed(3),
                    price_G2V_after: '-',
                    price_V2G_after: '-',
                    price_VPP_after: vpp['DR_Res 6'].price_VPP_after.toFixed(3)
                },
                DR_Res8: {
                    price_Grid_after: vpp['DR_Res 8'].price_Grid_after.toFixed(3),
                    price_G2V_after: '-',
                    price_V2G_after: '-',
                    price_VPP_after: vpp['DR_Res 8'].price_VPP_after.toFixed(3)
                }
            }
        );
    }, [props.data]);

    return (
        <Table striped bordered hover variant="dark">
            <thead>
                <tr>
                    <th>Demand Response</th>
                    <th className="text-4xl">LMP ($/kWh)</th>
                    <th className="text-4xl">G2V Price ($/kWh)</th>
                    <th className="text-4xl">V2G Price ($/kWh)</th>
                    <th className="text-4xl">Incentive Price for VPP ($/kWh)</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td className="text-center">AC EVSE 5</td>
                    <td className="text-center">{state.ACEVSE5?.price_Grid_after ?? '-'}</td>
                    <td className="text-center">{state.ACEVSE5?.price_G2V_after ?? '-'}</td>
                    <td className="text-center">{state.ACEVSE5?.price_V2G_after ?? '-'}</td>
                    <td className="text-center">{state.ACEVSE5?.price_VPP_after ?? '-'}</td>
                </tr>
                <tr>
                    <td className="text-center">AC EVSE 7</td>
                    <td className="text-center">{state.ACEVSE7?.price_Grid_after ?? '-'}</td>
                    <td className="text-center">{state.ACEVSE7?.price_G2V_after ?? '-'}</td>
                    <td className="text-center">{state.ACEVSE7?.price_V2G_after ?? '-'}</td>
                    <td className="text-center">{state.ACEVSE7?.price_VPP_after ?? '-'}</td>
                </tr>
                <tr>
                    <td className="text-center">AC EVSE 13</td>
                    <td className="text-center">{state.ACEVSE13?.price_Grid_after ?? '-'}</td>
                    <td className="text-center">{state.ACEVSE13?.price_G2V_after ?? '-'}</td>
                    <td className="text-center">{state.ACEVSE13?.price_V2G_after ?? '-'}</td>
                    <td className="text-center">{state.ACEVSE13?.price_VPP_after ?? '-'}</td>
                </tr>
                <tr>
                    <td className="text-center">DC EVSE 3</td>
                    <td className="text-center">{state.DCEVSE3?.price_Grid_after ?? '-'}</td>
                    <td className="text-center">{state.DCEVSE3?.price_G2V_after ?? '-'}</td>
                    <td className="text-center">{state.DCEVSE3?.price_V2G_after ?? '-'}</td>
                    <td className="text-center">{state.DCEVSE3?.price_VPP_after ?? '-'}</td>
                </tr>
                <tr>
                    <td className="text-center">DC EVSE 9</td>
                    <td className="text-center">{state.DCEVSE9?.price_Grid_after ?? '-'}</td>
                    <td className="text-center">{state.DCEVSE9?.price_G2V_after ?? '-'}</td>
                    <td className="text-center">{state.DCEVSE9?.price_V2G_after ?? '-'}</td>
                    <td className="text-center">{state.DCEVSE9?.price_VPP_after ?? '-'}</td>
                </tr>
                <tr>
                    <td className="text-center">DC EVSE 14</td>
                    <td className="text-center">{state.DCEVSE14?.price_Grid_after ?? '-'}</td>
                    <td className="text-center">{state.DCEVSE14?.price_G2V_after ?? '-'}</td>
                    <td className="text-center">{state.DCEVSE14?.price_V2G_after ?? '-'}</td>
                    <td className="text-center">{state.DCEVSE14?.price_VPP_after ?? '-'}</td>
                </tr>
                <tr>
                    <td className="text-center">DR Com 1</td>
                    <td className="text-center">{state.DR_Com1?.price_Grid_after ?? '-'}</td>
                    <td className="text-center">{state.DR_Com1?.price_G2V_after ?? '-'}</td>
                    <td className="text-center">{state.DR_Com1?.price_V2G_after ?? '-'}</td>
                    <td className="text-center">{state.DR_Com1?.price_VPP_after ?? '-'}</td>
                </tr>
                <tr>
                    <td className="text-center">DR Ind 12</td>
                    <td className="text-center">{state.DR_Ind12?.price_Grid_after ?? '-'}</td>
                    <td className="text-center">{state.DR_Ind12?.price_G2V_after ?? '-'}</td>
                    <td className="text-center">{state.DR_Ind12?.price_V2G_after ?? '-'}</td>
                    <td className="text-center">{state.DR_Ind12?.price_VPP_after ?? '-'}</td>
                </tr>
                <tr>
                    <td className="text-center">DR Res 4</td>
                    <td className="text-center">{state.DR_Res4?.price_Grid_after ?? '-'}</td>
                    <td className="text-center">{state.DR_Res4?.price_G2V_after ?? '-'}</td>
                    <td className="text-center">{state.DR_Res4?.price_V2G_after ?? '-'}</td>
                    <td className="text-center">{state.DR_Res4?.price_VPP_after ?? '-'}</td>
                </tr>
                <tr>
                    <td className="text-center">DR Res 6</td>
                    <td className="text-center">{state.DR_Res6?.price_Grid_after ?? '-'}</td>
                    <td className="text-center">{state.DR_Res6?.price_G2V_after ?? '-'}</td>
                    <td className="text-center">{state.DR_Res6?.price_V2G_after ?? '-'}</td>
                    <td className="text-center">{state.DR_Res6?.price_VPP_after ?? '-'}</td>
                </tr>
                <tr>
                    <td className="text-center">DR Res 8</td>
                    <td className="text-center">{state.DR_Res8?.price_Grid_after ?? '-'}</td>
                    <td className="text-center">{state.DR_Res8?.price_G2V_after ?? '-'}</td>
                    <td className="text-center">{state.DR_Res8?.price_V2G_after ?? '-'}</td>
                    <td className="text-center">{state.DR_Res8?.price_VPP_after ?? '-'}</td>
                </tr>
            </tbody>
        </Table>
    )
}