import { Card } from "components/Card/Card.jsx";
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { useSessionStorage } from 'primereact/hooks';
import React, { useEffect, useMemo, useRef, useState } from "react";
import { Col, ListGroup, ListGroupItem, Row, Table } from "react-bootstrap";
import Container from 'react-bootstrap/Container';
import { toast } from "react-toastify";
import { api } from '../api';
import '../utils';
import Animation from "./Animation";

export default function Scenarios() {
    const startScenarioButton = useRef(null);
    const [scenarioLogs, setScenarioLogs] = useState([]);
    const [animateionKey, setAnimateionKey] = useState(0);
    const [startAnimate, setStartAnimate] = useState(false);
    const [endAnimate, setEndAnimate] = useState(false);
    const [dateTime, setDateTime] = useState({});
    const [dateTimes, setDateTimes] = useState([]);
    const [resetData, setResetData] = useState(false);
    const [dateTimeStorage, setDateTimeStorage] = useSessionStorage(null, 'dateTime');
    const [scenarioId, setScenarioId] = useSessionStorage(null, 'scenarioId');
    const [messageTime, setMessageTime] = useState(3);
    const [animationTime, setAnimationTime] = useState(13);
    var id = sessionStorage.id;

    function setDefaultScenarioLogs() {
        const scenarioLogs = [];
        scenarioLogs.push({ title: 'DSO Offers to EVSE Aggregator' });
        scenarioLogs.push({ title: 'DSO Offers to VPP Aggregator' });
        scenarioLogs.push({ title: 'EVSE sends Response to DSO' });
        scenarioLogs.push({ title: 'VPP Response to DSO' });
        scenarioLogs.push({ title: 'DSO Finalizes EVSE Offer' });
        scenarioLogs.push({ title: 'DSO Finalizes VPP Offer' });
        scenarioLogs.push({ title: 'Network Status Updated' });
        setScenarioLogs(scenarioLogs);
    }

    function load() {
        setDefaultScenarioLogs();

        sessionStorage.setItem('scenarioCounter', 0);
        sessionStorage.setItem('statusIsFinalized', false);

        const dateTimes = [];
        dateTimes.push({ label: "2022-07-20 02:00 Off-Peak", value: "2022-07-20 02:00:00" });
        dateTimes.push({ label: "2022-07-20 16:00 On-Peak", value: "2022-07-20 16:00:00" });
        dateTimes.push({ label: "2022-07-21 02:00 Off-Peak", value: "2022-07-21 02:00:00" });
        dateTimes.push({ label: "2022-07-21 18:00 On-Peak", value: "2022-07-21 18:00:00" });
        dateTimes.push({ label: "2022-08-26 02:00 Off-Peak", value: "2022-08-26 02:00:00" });
        dateTimes.push({ label: "2022-08-26 17:00 On-Peak", value: "2022-08-26 17:00:00" });
        dateTimes.push({ label: "2022-08-29 02:00 Off-Peak", value: "2022-08-29 02:00:00" });
        dateTimes.push({ label: "2022-08-29 17:00 On-Peak", value: "2022-08-29 17:00:00" });
        setDateTimes(dateTimes);

        setDateTime(JSON.parse(sessionStorage.dateTime).value);

        if (sessionStorage.scenarioLogs) {
            setAnimationTime(0);
            setMessageTime(.1);
            setTimeout(() => {
                const scenarioCounter = parseInt(sessionStorage.getItem('scenarioCounter'));
                setStartAnimate(true);
                sessionStorage.setItem('scenarioCounter', scenarioCounter + 1);
            }, 500);

            setScenarioLogs(JSON.parse(sessionStorage.scenarioLogs));
        }
    }

    useEffect(load, []);

    function makeId(length) {
        let result = '';
        const characters = 'abcdefghijklmnopqrstuvwxyz';
        const charactersLength = characters.length;
        let counter = 0;
        while (counter < length) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
            counter += 1;
        }
        return result;
    }

    useEffect(() => {
        try {
            if (dateTimes.length > 0) {
                if (dateTime) {
                    console.debug('dateTime', dateTime, sessionStorage.scenarioId);

                    setDateTimeStorage(dateTimes.find(e => e.value === dateTime));
                    setAnimateionKey(new Date().getTime());

                    console.debug('scenarioLogs', sessionStorage.scenarioLogs);

                    // if (sessionStorage.scenarioLogs) {

                    // }
                    //else {
                    let scenarioId = dateTime + ' ' + id;
                    setScenarioId(scenarioId);
                    //}
                }
            }
        }
        catch (e) {
            console.error('Error Datetime', e);
        }
    }, [dateTime, dateTimes]);

    const [startScenarioFlag, setStartScenarioFlag] = useState(false);

    async function startScenario() {
        // setDateTimeStorage(dateTimes.find(e => e.value === dateTime));
        // setAnimateionKey(new Date().getTime());
        setResetData(new Date().getTime());

        //const scenarioId = dateTime + ' ' + makeid(5);
        //setScenarioId(scenarioId);

        const scenarioCounter = parseInt(sessionStorage.getItem('scenarioCounter'));
        // const timer = 0;
        const timer = 2000;

        setStartScenarioFlag(true);

        scenarioLogs.map(scenarioLog => {
            delete scenarioLog.description;
            delete scenarioLog.params;
            delete scenarioLog.icon;
            delete scenarioLog.statusColor;
        });
        setScenarioLogs([...scenarioLogs]);

        if (scenarioCounter >= 1) {
            setStartAnimate(false);
            // setEndAnimate(false);
            sessionStorage.setItem('statusIsFinalized', false);
        }

        const res = await api.get(`/run_opf/${scenarioId}`).catch(() => {
            setStartScenarioFlag(false);
            toast.error('Error run scenario, please contact administrator');
        });
        sessionStorage.run_opf = JSON.stringify(res.data);

        {
            const scenarioItem = scenarioLogs.find(e => e.title === 'DSO Offers to EVSE Aggregator');
            const item1 = res.data['DSO Offer to EVSE Aggregator']['load after'];
            const item2 = res.data['DSO Offer to EVSE Aggregator'];

            console.debug('EVSE - Profit Bonus (%)',
                item2['profit_offer_by_DSO_to_EVSE_percent']['profit_offer_by_DSO_to_EVSE_percent'],
                parseFloat(item2['profit_offer_by_DSO_to_EVSE_percent']['profit_offer_by_DSO_to_EVSE_percent'])
            );

            scenarioItem.type = 1;
            scenarioItem.statusColor = 'green';
            scenarioItem.iocn = 'fa fa-check-circle';
            // scenarioItem.iocn = 'fa fa-clock-o';
            scenarioItem.params = [{
                title: 'Max Load (kW)',
                EVSE5: item1['ACEVSE 5'], EVSE7: item1['ACEVSE 7'], EVSE13: item1['ACEVSE 13'],
                EVSE3: item1['DCEVSE 3'], EVSE9: item1['DCEVSE 9'], EVSE14: item1['DCEVSE 14']
            }, {
                title: 'Profit Bonus (%)',
                EVSE5: parseFloat(item2['profit_offer_by_DSO_to_EVSE_percent']['profit_offer_by_DSO_to_EVSE_percent']) - 25,
            }];
        }
        setScenarioLogs([...scenarioLogs]);
        await new Promise(r => setTimeout(r, timer));

        {
            const scenarioItem = scenarioLogs.find(e => e.title === 'DSO Offers to VPP Aggregator');
            const item1 = res.data['DSO Offer to VPP Aggregator']['load after'];
            const item2 = res.data['DSO Offer to VPP Aggregator']['Discount_offer_by_DSO_to_VPP_percent'];
            scenarioItem.type = 2;
            scenarioItem.statusColor = 'green';
            scenarioItem.iocn = 'fa fa-check-circle';
            // scenarioItem.iocn = 'fa fa-clock-o';
            scenarioItem.params = [{
                title: 'Max Load (kW)',
                DR_Com1: item1['DR_Com 1'], DR_Res4: item1['DR_Res 4'], DR_Res6: item1['DR_Res 6'],
                DR_Res8: item1['DR_Res 8'], DR_Ind12: item1['DR_Ind 12']
            }, {
                title: 'Discount Bonus (%)',
                com: item2['commertial_discount_rate_offer_by_DSO_to_VPP_percent'],
                res: item2['residential_discount_rate_offer_by_DSO_to_VPP_percent'],
                ind: item2['industrial_discount_rate_offer_by_DSO_to_VPP_percent']
            }];
        }
        setScenarioLogs([...scenarioLogs]);
        await new Promise(r => setTimeout(r, timer));

        {
            const scenarioItem = scenarioLogs.find(e => e.title === 'EVSE sends Response to DSO');
            const item1 = res.data['EVSE Aggregator response to DSO']['load after'];
            const item2 = res.data['EVSE Aggregator response to DSO'];
            scenarioItem.type = 3;
            scenarioItem.statusColor = 'green';
            scenarioItem.iocn = 'fa fa-check-circle';
            // scenarioItem.iocn = 'fa fa-clock-o';
            scenarioItem.params = [{
                title: 'Max Load (kW)',
                EVSE5: item1['ACEVSE 5'], EVSE7: item1['ACEVSE 7'], EVSE13: item1['ACEVSE 13'],
                EVSE3: item1['DCEVSE 3'], EVSE9: item1['DCEVSE 9'], EVSE14: item1['DCEVSE 14']
            }, {
                title: 'Profit Bonus (%)',
                EVSE5: parseFloat(item2['profit_offer_by_EVSE_to_DSO_percent']['profit_offer_by_EVSE_to_DSO_percent']) - 25,
            }];
        }
        setScenarioLogs([...scenarioLogs]);
        await new Promise(r => setTimeout(r, timer));

        {
            const scenarioItem = scenarioLogs.find(e => e.title === 'VPP Response to DSO');
            const item1 = res.data['VPP Aggregator response to DSO']['load after'];
            const item2 = res.data['VPP Aggregator response to DSO']['discount_rate_offer_by_VPP_to_DSO_percent'];
            scenarioItem.type = 4;
            scenarioItem.statusColor = 'green';
            scenarioItem.iocn = 'fa fa-check-circle';
            // scenarioItem.iocn = 'fa fa-clock-o';
            scenarioItem.params = [{
                title: 'Max Load (kW)',
                DR_Com1: item1['DR_Com 1'], DR_Res4: item1['DR_Res 4'], DR_Res6: item1['DR_Res 6'],
                DR_Res8: item1['DR_Res 8'], DR_Ind12: item1['DR_Ind 12']
            }, {
                title: 'Discount Bonus (%)',
                com: item2['commertial_discount_rate_offer_by_VPP_to_DSO_percent'] ?? item2['commertial_discount_rate_offer_by_DSO_to_VPP_percent'],
                res: item2['residential_discount_rate_offer_by_VPP_to_DSO_percent'] ?? item2['residential_discount_rate_offer_by_DSO_to_VPP_percent'],
                ind: item2['industrial_discount_rate_offer_by_VPP_to_DSO_percent'] ?? item2['industrial_discount_rate_offer_by_DSO_to_VPP_percent']
            }];
        }
        setScenarioLogs([...scenarioLogs]);
        await new Promise(r => setTimeout(r, timer));

        {
            const scenarioItem = scenarioLogs.find(e => e.title === 'DSO Finalizes EVSE Offer');
            // const item1 = res.data['Final Status of EVSE and VPP loads']['energy'][0];
            const item1 = res.data['EVSE Aggregator response to DSO']['load after'];
            const item2 = res.data['Final Status of EVSE and VPP loads'];
            const item3 = res.data['EVSE Aggregator response to DSO'];
            scenarioItem.type = 5;
            scenarioItem.statusColor = 'green';
            scenarioItem.iocn = 'fa fa-check-circle';
            // scenarioItem.iocn = 'fa fa-clock-o';
            scenarioItem.params = [{
                title: 'Max Load (kW)',
                EVSE5: item1['ACEVSE 5'], EVSE7: item1['ACEVSE 7'], EVSE13: item1['ACEVSE 13'],
                EVSE3: item1['DCEVSE 3'], EVSE9: item1['DCEVSE 9'], EVSE14: item1['DCEVSE 14']
            }, {
                title: 'Profit Bonus (%)',
                EVSE5: parseFloat(item3['profit_offer_by_EVSE_to_DSO_percent']['profit_offer_by_EVSE_to_DSO_percent']) - 25,
            }];
        }
        setScenarioLogs([...scenarioLogs]);
        await new Promise(r => setTimeout(r, timer));

        {
            const scenarioItem = scenarioLogs.find(e => e.title === 'DSO Finalizes VPP Offer');
            // const item1 = res.data['Final Status of EVSE and VPP loads']['energy'][0];
            const item1 = res.data['VPP Aggregator response to DSO']['load after'];
            const item2 = res.data['Final Status of EVSE and VPP loads'];
            const item3 = res.data['VPP Aggregator response to DSO']['discount_rate_offer_by_VPP_to_DSO_percent'];
            scenarioItem.type = 6;
            scenarioItem.statusColor = 'green';
            scenarioItem.iocn = 'fa fa-check-circle';
            // scenarioItem.iocn = 'fa fa-clock-o';
            scenarioItem.params = [{
                title: 'Max Load (kW)',
                DR_Com1: item1['DR_Com 1'], DR_Res4: item1['DR_Res 4'], DR_Res6: item1['DR_Res 6'],
                DR_Res8: item1['DR_Res 8'], DR_Ind12: item1['DR_Ind 12']
            }, {
                title: 'Discount Bonus (%)',
                com: item3['commertial_discount_rate_offer_by_VPP_to_DSO_percent'] ?? item3['commertial_discount_rate_offer_by_DSO_to_VPP_percent'],
                res: item3['residential_discount_rate_offer_by_VPP_to_DSO_percent'] ?? item3['residential_discount_rate_offer_by_DSO_to_VPP_percent'],
                ind: item3['industrial_discount_rate_offer_by_VPP_to_DSO_percent'] ?? item3['industrial_discount_rate_offer_by_DSO_to_VPP_percent']
            }];
        }
        setScenarioLogs([...scenarioLogs]);
        await new Promise(r => setTimeout(r, timer));

        setTimeout(() => {
            setMessageTime(3);
            setAnimationTime(13);
            setStartAnimate(true);
            sessionStorage.setItem('scenarioCounter', scenarioCounter + 1);
        }, 500);

        {
            const scenarioItem = scenarioLogs.find(e => e.title === 'Network Status Updated');
            scenarioItem.statusColor = 'green';
            scenarioItem.iocn = 'fa fa-check-circle';
        }
        setScenarioLogs([...scenarioLogs]);
        await new Promise(r => setTimeout(r, timer));

        sessionStorage.scenarioLogs = JSON.stringify(scenarioLogs);
        sessionStorage.runScenario = true;
        setStartScenarioFlag(false);
    }

    function endAnimateEvent() {
        // const accepted = scenarioLogs.find(e => e.title === 'Accepted EV cars attached to EVSEs');
        // accepted.statusColor = 'green';
        // accepted.iocn = 'fa fa-check-circle';

        // const statusIsFinalized = sessionStorage.getItem('statusIsFinalized');
        // if (statusIsFinalized === 'true') {
        //     const statusIsFinalized = scenarioLogs.find(e => e.title === 'The network status is finalized');
        //     statusIsFinalized.statusColor = 'green';
        //     statusIsFinalized.iocn = 'fa fa-check-circle';

        //     const startButton = document.getElementById('startScenarioButton');
        //     startButton.removeAttribute('disabled');

        //     // scenarioStorage();
        // }

        // setAnimateionKey(new Date().getTime());
        // setEndAnimate(true);
        // setScenarioLogs([...scenarioLogs]);
    }

    // function scenarioStorage() {
    //     if (sessionStorage.scenarioId) {
    //         const _scenario_ids = JSON.parse(JSON.parse(JSON.stringify(sessionStorage.scenarioId)));
    //         let _scenario_id = _scenario_ids.find(e => e.dateTime === dateTime);

    //         if (_scenario_id)
    //             _scenario_id = { dateTime, scenarioId };
    //         else
    //             _scenario_ids.push({ dateTime, scenarioId });

    //         sessionStorage.setItem('scenario_id', JSON.stringify(_scenario_ids));
    //     }
    //     else {
    //         const _scenario_ids = [];
    //         _scenario_ids.push({ dateTime, scenarioId });
    //         sessionStorage.setItem('scenario_id', JSON.stringify(_scenario_ids));
    //     }
    // }

    const evseType = (scenarioLog) => {
        if (scenarioLog.params === undefined || scenarioLog.params.length === 0)
            return;

        return (
            <Table responsive id='logs2' style={{ color: 'black' }}>
                <thead>
                    <tr>
                        <th></th>
                        <th className="w-2">AC 5</th>
                        <th className="w-2">AC 7</th>
                        <th className="w-2">AC 13</th>
                        <th className="w-2">DC 3</th>
                        <th className="w-2">DC 9</th>
                        <th className="w-2">DC 14</th>
                    </tr>
                </thead>
                <tbody>
                    {scenarioLog.params?.map(param => {
                        return (
                            <tr>
                                <td>{param.title}</td>
                                <td>{param.EVSE5?.toFixed(3)}</td>
                                <td>{param.EVSE7?.toFixed(3)}</td>
                                <td>{param.EVSE13?.toFixed(3)}</td>
                                <td>{param.EVSE3?.toFixed(3)}</td>
                                <td>{param.EVSE9?.toFixed(3)}</td>
                                <td>{param.EVSE14?.toFixed(3)}</td>
                            </tr>
                        )
                    })}
                </tbody>
            </Table>
        )
    }

    const vppType = (scenarioLog) => {
        if (scenarioLog.params === undefined || scenarioLog.params.length === 0)
            return;

        return <Table responsive id='logs2' style={{ color: 'black' }}>
            <thead>
                <tr>
                    <th></th>
                    <th className="w-2">Com. 1</th>
                    <th className="w-2">Res. 4</th>
                    <th className="w-2">Res. 6</th>
                    <th className="w-2">Res. 8</th>
                    <th className="w-2">Ind. 12</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>{scenarioLog.params[0].title}</td>
                    <td>{scenarioLog.params[0].DR_Com1?.toFixed(3)}</td>
                    <td>{scenarioLog.params[0].DR_Res4?.toFixed(3)}</td>
                    <td>{scenarioLog.params[0].DR_Res6?.toFixed(3)}</td>
                    <td>{scenarioLog.params[0].DR_Res8?.toFixed(3)}</td>
                    <td>{scenarioLog.params[0].DR_Ind12?.toFixed(3)}</td>
                </tr>
            </tbody>

            {scenarioLog.params[1] &&
                <>
                    <thead>
                        <tr>
                            <th></th>
                            <th className="w-2">Com.</th>
                            <th className="w-2">Res.</th>
                            <th className="w-2">Ind.</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{scenarioLog.params[1].title}</td>
                            <td>{scenarioLog.params[1].com?.toFixed(3)}</td>
                            <td>{scenarioLog.params[1].res?.toFixed(3)}</td>
                            <td>{scenarioLog.params[1].ind?.toFixed(3)}</td>
                        </tr>
                    </tbody>
                </>
            }
        </Table>
    }

    return <Container fluid className="content">
        <Row>
            <Col lg={12} xl={12}>
                <Card title="Scenario" content={
                    <>
                        <Row>
                            <Col lg={4} xl={4} className='flex'>
                                <Button ref={startScenarioButton}
                                    loading={startScenarioFlag}
                                    id={'startScenarioButton'}
                                    onClick={startScenario}
                                    severity="danger"
                                    className="w-4 text-center"
                                    label="Run Scenario"
                                    style={{ fontSize: '1.4rem', height: '4rem', fontWeight: 600 }} />
                                <Dropdown value={dateTime} options={dateTimes}
                                    disabled={startScenarioFlag}
                                    style={{ marginLeft: '.5rem' }}
                                    onChange={e => {
                                        id = makeId(5);
                                        const scenarioId = e.value + ' ' + id;
                                        sessionStorage.scenarioId = scenarioId;
                                        sessionStorage.removeItem('run_opf');
                                        sessionStorage.removeItem('scenarioLogs');
                                        sessionStorage.runScenario = false;
                                        setDefaultScenarioLogs();
                                        setScenarioId(scenarioId);
                                        setDateTime(e.value);
                                    }}
                                    className="w-8"
                                    placeholder="Select a Date" />
                            </Col>
                        </Row>
                        <Row style={{ marginTop: '.5rem' }}>

                            <Col lg={4} xl={4}>
                                <ListGroup>{scenarioLogs.map((scenarioLog, i) => {
                                    const colStyle = { fontSize: '1.3rem', overflow: 'auto' };
                                    const iconStyle = { fontSize: '1.9rem', color: scenarioLog.statusColor ? scenarioLog.statusColor : 'gray' };

                                    return <ListGroupItem style={{ backgroundColor: i % 2 === 0 ? '#fff' : '#eee' }}>
                                        <Row>
                                            <Col lg={12} xl={12} style={colStyle}>
                                                <Row>
                                                    <i className={scenarioLog.iocn ? scenarioLog.iocn : 'fa fa-times-circle'} style={iconStyle}></i>
                                                    {' '}
                                                    <span style={{ fontWeight: "bolder" }}>
                                                        {scenarioLog.title}
                                                    </span>
                                                </Row>
                                                <Row>
                                                    {scenarioLog.description ?
                                                        <span style={{ ...colStyle, color: scenarioLog.descriptionColor, fontWeight: "bolder" }}>
                                                            {scenarioLog.description}
                                                        </span>
                                                        : ''}
                                                </Row>
                                                <Row>
                                                    <Col lg={12} xl={12}>
                                                        {scenarioLog.type === 1 && evseType(scenarioLog)}
                                                        {scenarioLog.type === 2 && vppType(scenarioLog)}
                                                        {scenarioLog.type === 3 && evseType(scenarioLog)}
                                                        {scenarioLog.type === 4 && vppType(scenarioLog)}
                                                        {scenarioLog.type === 5 && evseType(scenarioLog)}
                                                        {scenarioLog.type === 6 && vppType(scenarioLog)}
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </ListGroupItem>
                                })}</ListGroup>
                            </Col>
                            <Col xl={8} lg={8}>
                                {useMemo(() => {
                                    return (
                                        <Animation key={animateionKey} startAnimate={startAnimate} messageTime={messageTime} animationTime={animationTime}
                                            endAnimate={endAnimateEvent} scenarioId={scenarioId} resetData={resetData} />
                                    )
                                }, [startAnimate, scenarioId, resetData, animateionKey])}
                            </Col>
                        </Row>
                    </>
                } />
            </Col>
        </Row>
    </Container >
}
