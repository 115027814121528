import React from "react";
import ChartistGraph from "react-chartist";
import { Col, ListGroup, ListGroupItem } from "react-bootstrap";
import { Card } from "components/Card/Card.jsx";
import { Progress } from 'antd';

export default function NodeStatus(props) {
    const totalNodes = props.active + props.standBy + props.disabled;

    const nodeStatus = {
        labels: ["Active", "Standby", "Disabled"],
        series: [{
            value: props.active,
            name: 'Series 1',
            className: 'active',
        }, {
            value: props.standBy,
            name: 'Series 2',
            className: 'standby',
        }, {
            value: props.disabled,
            name: 'Series 3',
            className: 'disabled',
        }]
    };

    const success = ((props.active / totalNodes) * 100);
    const percent = ((props.standBy / totalNodes) * 100) + success;

    return (
        <Col lg={3} sm={6}>
            <Card title="Node Status"
                content={
                    <section className="flex h-13rem">
                        <div className="w-6 justify-content-center">
                            <ListGroup className="list-group-flush">
                                <ListGroupItem style={{ border: '0 none' }} className='md:text-lg'>
                                    <i className="fa fa-play active text-2xl" ></i>
                                    <span className="text-2xl">Active</span>
                                    <span className="text-2xl" style={{ float: 'right' }}>{props.active}</span>
                                </ListGroupItem>
                                <ListGroupItem style={{ border: '0 none' }} className='md:text-lg'>
                                    <i className="fa fa-pause standby text-2xl" />
                                    <span className="text-2xl">Standby</span>
                                    <span className="text-2xl" style={{ float: 'right' }}>{props.standBy}</span>
                                </ListGroupItem>
                                <ListGroupItem style={{ border: '0 none' }} className='md:text-lg'>
                                    <i className="fa fa-stop disabled text-2xl" ></i>
                                    <span className="text-2xl">Disabled</span>
                                    <span className="text-2xl" style={{ float: 'right' }}>{props.disabled}</span>
                                </ListGroupItem>
                            </ListGroup>
                        </div>
                        <div className="w-6 md:text-lg flex justify-content-center">
                            <Progress className="total-nodes"
                                format={(percent) => `Total Nodes \n\r ${totalNodes}`}
                                percent={percent} strokeColor={'#FFCC66'}
                                success={{ percent: success, strokeColor: '#00CC99' }}
                                type="circle" />
                        </div>
                    </section >
                }
            />
        </Col>
    )
}