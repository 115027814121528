import React, { useEffect, useState } from "react";
import {
    ComposableMap,
    Geographies,
    Geography,
    Marker
} from "react-simple-maps";
import { Card } from "components/Card/Card.jsx";

const geoUrl =
//  `http://${window.location.origin}/world-110m.json`;
    "https://unpkg.com/world-atlas@1.1.4/world/110m.json";
    // "https://raw.githubusercontent.com/zcreativelabs/react-simple-maps/master/topojson-maps/world-110m.json";

function MapChart(props) {
    const [markers, setMarkers] = useState([]);

    useEffect(e => {
        props.nodeLocations.forEach(location => {
            markers.push({
                name: '',
                markerOffset: 6,
                coordinates: [location.Long, location.lat]
            });
        });
        setMarkers(markers);
    }, [props.nodeLocations]);

    return (
        <Card
            title="Node distributed by location"
            style={{ minHeight: '67rem' }}
            content={
                <ComposableMap>
                    <Geographies geography={geoUrl}>
                        {({ geographies }) =>
                            geographies.map(geo => (
                                <Geography
                                    key={geo.rsmKey}
                                    geography={geo}
                                    fill="#EAEAEC"
                                    stroke="#D6D6DA"
                                />
                            ))
                        }
                    </Geographies>
                    {markers.map(({ name, coordinates, markerOffset }, i) => (
                        <Marker key={i} coordinates={coordinates}>
                            <g
                                fill="none"
                                stroke="#FF5533"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                transform="translate(-12, -24)"
                                height="30"
                                width="30"
                            >
                                <circle cx="12" cy="10" r="3" />
                                <path d="M12 21.7C17.3 17 20 13 20 10a8 8 0 1 0-16 0c0 3 2.7 6.9 8 11.7z" />
                            </g>
                            {
                                name && <text
                                    textAnchor="middle"
                                    y={markerOffset}
                                    style={{ fontFamily: "system-ui", fill: "#5D5A6D", fontSize: '.8rem' }}
                                >{name}</text>
                            }
                        </Marker>
                    ))}
                </ComposableMap>
            }></Card>
    );
};

export default MapChart;
