import React, { useEffect, useRef, useState } from "react";
import { Card } from "components/Card/Card.jsx";
import Plotly from "plotly.js-dist";
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { SplitButton } from 'primereact/splitbutton';
import { Col, Row, Table } from "react-bootstrap";
import Container from 'react-bootstrap/Container';
import createPlotlyComponent from "react-plotly.js/factory";
import { toast } from "react-toastify";
import { Spinner } from '../Spinner';
import BusLMPChart from "./forecast/BusLMPChart";
import NetworkLoad from "./forecast/NetworkLoad";
import PVOutput from "./forecast/PVOutput";
import { isNil } from 'lodash';
import initNetwork from "../initNetwork.json";

export default function Network() {
    const [graphData, setGraphData] = useState([]);
    const [summary, setSummary] = useState({});
    const [loading, setLoading] = useState(true);

    const [busDatas, setBusDatas] = useState([]);
    const busDataRef = useRef(null);

    const [lamDatas, setLamDatas] = useState([]);
    const lamDatasRef = useRef(null);

    const [lineDatas, setLineDatas] = useState([]);
    const lineDatasRef = useRef(null);

    const [transformerDatas, setTransformerDatas] = useState([]);
    const transformerDataRef = useRef(null);

    const [derDatas, setDerDatas] = useState([]);
    const derDataRef = useRef(null);

    const [transactiveEnergyData, setTransactiveEnergyData] = useState([]);
    const transactiveEnergyDataRef = useRef(null);

    const [forecasted, setForecasted] = useState({});

    useEffect(() => {
        if (sessionStorage.scenarioId) {
            if (JSON.parse(sessionStorage.runScenario) && sessionStorage.run_opf) {
                loadData(JSON.parse(sessionStorage.run_opf));
            }
            else {
                setTimeout(() => {
                    loadData(initNetwork[JSON.parse(sessionStorage.dateTime).value]);
                }, 2000);
            }

            function loadData(data) {
                let _data = data;

                if (JSON.parse(sessionStorage.runScenario))
                    _data = data['network data after run physical network'];

                let graphData = _data.graph_data.graph_data.replace(/'/g, '"');
                graphData = graphData.replaceAll('False', false);
                graphData = graphData.replaceAll('True', true);
                graphData = graphData.replaceAll('1. ', '1.0');
                console.debug('graphData', graphData);
                setGraphData(JSON.parse(graphData));

                setSummary(_data.summary_table);
                getBusData(_data.bus_data);
                getLineDatas(_data.line_data);
                getTransformerData(_data.transformer_data);
                getDerData(_data.der_data);
                getTransactiveEnergyData();

                setForecasted({
                    networkLoad: data['Forecasted Data']['hourly total network load (kW)'],
                    busLMP: data['Forecasted Data']['hourly slack bus lmp ($/kW)'],
                    PVoutput: data['Forecasted Data']['hourly PV output (kW)'],
                });

                setLoading(false);
            }
        }
        else {
            toast.error("No run scenario, Please run");
            setLoading(false);
        }
    }, []);

    function getTransactiveEnergyData() {
        if (isNil(sessionStorage.run_opf)) {
            setTransactiveEnergyData([
                {
                    demandResponse: 'AC EVSE 5',
                    grid_price: initNetwork[JSON.parse(sessionStorage.dateTime).value].bus_data.lam_p['5'].toFixed(3),
                    g2v_price: '-',
                    v2g_price: '-',
                    vpp_price: '-',
                },
                {
                    demandResponse: 'AC EVSE 7',
                    grid_price: initNetwork[JSON.parse(sessionStorage.dateTime).value].bus_data.lam_p['7'].toFixed(3),
                    g2v_price: '-',
                    v2g_price: '-',
                    vpp_price: '-',
                },
                {
                    demandResponse: 'AC EVSE 13',
                    grid_price: initNetwork[JSON.parse(sessionStorage.dateTime).value].bus_data.lam_p['13'].toFixed(3),
                    g2v_price: '-',
                    v2g_price: '-',
                    vpp_price: '-',
                },
                {
                    demandResponse: 'DC EVSE 3',
                    grid_price: initNetwork[JSON.parse(sessionStorage.dateTime).value].bus_data.lam_p['3'].toFixed(3),
                    g2v_price: '-',
                    v2g_price: '-',
                    vpp_price: '-',
                },
                {
                    demandResponse: 'DC EVSE 9',
                    grid_price: initNetwork[JSON.parse(sessionStorage.dateTime).value].bus_data.lam_p['9'].toFixed(3),
                    g2v_price: '-',
                    v2g_price: '-',
                    vpp_price: '-',
                },
                {
                    demandResponse: 'DC EVSE 14',
                    grid_price: initNetwork[JSON.parse(sessionStorage.dateTime).value].bus_data.lam_p['14'].toFixed(3),
                    g2v_price: '-',
                    v2g_price: '-',
                    vpp_price: '-',
                },
                {
                    demandResponse: 'DR_Com 1',
                    grid_price: initNetwork[JSON.parse(sessionStorage.dateTime).value].bus_data.lam_p['1'].toFixed(3),
                    g2v_price: '-',
                    v2g_price: '-',
                    vpp_price: '-'
                },
                {
                    demandResponse: 'DR_Ind 12',
                    grid_price: initNetwork[JSON.parse(sessionStorage.dateTime).value].bus_data.lam_p['12'].toFixed(3),
                    g2v_price: '-',
                    v2g_price: '-',
                    vpp_price: '-'
                },
                {
                    demandResponse: 'DR_Res 4',
                    grid_price: initNetwork[JSON.parse(sessionStorage.dateTime).value].bus_data.lam_p['4'].toFixed(3),
                    g2v_price: '-',
                    v2g_price: '-',
                    vpp_price: '-'
                },
                {
                    demandResponse: 'DR_Res 6',
                    grid_price: initNetwork[JSON.parse(sessionStorage.dateTime).value].bus_data.lam_p['6'].toFixed(3),
                    g2v_price: '-',
                    v2g_price: '-',
                    vpp_price: '-'
                },
                {
                    demandResponse: 'DR_Res 8',
                    grid_price: initNetwork[JSON.parse(sessionStorage.dateTime).value].bus_data.lam_p['8'].toFixed(3),
                    g2v_price: '-',
                    v2g_price: '-',
                    vpp_price: '-'
                }
            ]);
            return;
        }

        const run_opf = JSON.parse(sessionStorage.run_opf);
        const evse_ev = run_opf['evse_ev_broadcast'];
        const vpp = run_opf['vpp_broadcast'];

        setTransactiveEnergyData([
            {
                demandResponse: 'AC EVSE 5',
                grid_price: evse_ev['ACEVSE 5'].price_Grid_after.toFixed(3),
                g2v_price: evse_ev['ACEVSE 5'].price_G2V_after.toFixed(3),
                v2g_price: '-',
                vpp_price: '-',
            },
            {
                demandResponse: 'AC EVSE 7',
                grid_price: evse_ev['ACEVSE 7'].price_Grid_after.toFixed(3),
                g2v_price: evse_ev['ACEVSE 7'].price_G2V_after.toFixed(3),
                v2g_price: '-',
                vpp_price: '-',
            },
            {
                demandResponse: 'AC EVSE 13',
                grid_price: evse_ev['ACEVSE 13'].price_Grid_after.toFixed(3),
                g2v_price: evse_ev['ACEVSE 13'].price_G2V_after.toFixed(3),
                v2g_price: '-',
                vpp_price: '-',
            },
            {
                demandResponse: 'DC EVSE 3',
                grid_price: evse_ev['DCEVSE 3'].price_Grid_after.toFixed(3),
                g2v_price: evse_ev['DCEVSE 3'].price_G2V_after.toFixed(3),
                v2g_price: evse_ev['DCEVSE 3'].price_V2G_after.toFixed(3),
                vpp_price: '-',
            },
            {
                demandResponse: 'DC EVSE 9',
                grid_price: evse_ev['DCEVSE 9'].price_Grid_after.toFixed(3),
                g2v_price: evse_ev['DCEVSE 9'].price_G2V_after.toFixed(3),
                v2g_price: evse_ev['DCEVSE 9'].price_V2G_after.toFixed(3),
                vpp_price: '-',
            },
            {
                demandResponse: 'DC EVSE 14',
                grid_price: evse_ev['DCEVSE 14'].price_Grid_after.toFixed(3),
                g2v_price: evse_ev['DCEVSE 14'].price_G2V_after.toFixed(3),
                v2g_price: evse_ev['DCEVSE 14'].price_V2G_after.toFixed(3),
                vpp_price: '-',
            },
            {
                demandResponse: 'DR_Com 1',
                grid_price: vpp['DR_Com 1'].price_Grid_after.toFixed(3),
                g2v_price: '-',
                v2g_price: '-',
                vpp_price: vpp['DR_Com 1'].price_VPP_after.toFixed(3)
            },
            {
                demandResponse: 'DR_Ind 12',
                grid_price: vpp['DR_Ind 12'].price_Grid_after.toFixed(3),
                g2v_price: '-',
                v2g_price: '-',
                vpp_price: vpp['DR_Ind 12'].price_VPP_after.toFixed(3)
            },
            {
                demandResponse: 'DR_Res 4',
                grid_price: vpp['DR_Res 4'].price_Grid_after.toFixed(3),
                g2v_price: '-',
                v2g_price: '-',
                vpp_price: vpp['DR_Res 4'].price_VPP_after.toFixed(3)
            },
            {
                demandResponse: 'DR_Res 6',
                grid_price: vpp['DR_Res 6'].price_Grid_after.toFixed(3),
                g2v_price: '-',
                v2g_price: '-',
                vpp_price: vpp['DR_Res 6'].price_VPP_after.toFixed(3)
            },
            {
                demandResponse: 'DR_Res 8',
                grid_price: vpp['DR_Res 8'].price_Grid_after.toFixed(3),
                g2v_price: '-',
                v2g_price: '-',
                vpp_price: vpp['DR_Res 8'].price_VPP_after.toFixed(3)
            }
        ]);
    }

    function getBusData(data) {
        const datas = [];
        const lams = [];

        for (let i = 0; i < Object.keys(data.p_mw).length; i++) {
            datas.push({
                'Bus number': Object.values(data['Bus number'])[i],
                p_mw: Object.values(data.p_mw)[i],
                q_mvar: Object.values(data.q_mvar)[i],
                va_degree: Object.values(data.va_degree)[i],
                vm_pu: Object.values(data.vm_pu)[i],
            });
            lams.push({
                'Bus number': Object.values(data['Bus number'])[i],
                lam: Object.values(data.lam_p)[i],
            });
        }
        setLamDatas(lams);
        setBusDatas(datas);
    }

    function getLineDatas(data) {
        const datas = [];
        for (let i = 0; i < Object.keys(data.i_from_ka).length; i++) {
            datas.push({
                'Line name': Object.values(data['Line name'])[i],
                i_from_ka: Object.values(data.i_from_ka)[i],
                i_ka: Object.values(data.i_ka)[i],
                i_to_ka: Object.values(data.i_to_ka)[i],
                loading_percent: Object.values(data.loading_percent)[i],
                p_from_mw: Object.values(data.p_from_mw)[i],
                p_to_mw: Object.values(data.p_to_mw)[i],
                pl_mw: Object.values(data.pl_mw)[i],
                q_from_mvar: Object.values(data.q_from_mvar)[i],
                q_to_mvar: Object.values(data.q_to_mvar)[i],
                ql_mvar: Object.values(data.ql_mvar)[i],
                ql_mvar: Object.values(data.ql_mvar)[i],
                va_from_degree: Object.values(data.va_from_degree)[i],
                va_to_degree: Object.values(data.va_to_degree)[i],
                vm_from_pu: Object.values(data.vm_from_pu)[i],
            });
        }

        setLineDatas(datas);

    }

    function getTransformerData(data) {
        const datas = [];

        try {
            for (let i = 0; i < Object.keys(data.i_hv_ka).length; i++) {
                datas.push({
                    'Transformer name': Object.values(data['Transformer name'])[i],
                    i_hv_ka: Object.values(data.i_hv_ka)[i],
                    i_lv_ka: Object.values(data.i_lv_ka)[i],
                    loading_percent: Object.values(data.loading_percent)[i],
                    p_hv_mw: Object.values(data.p_hv_mw)[i],
                    p_lv_mw: Object.values(data.p_lv_mw)[i],
                    pl_mw: Object.values(data.pl_mw)[i],
                    q_hv_mvar: Object.values(data.q_hv_mvar)[i],
                    q_lv_mvar: Object.values(data.q_lv_mvar)[i],
                    ql_mvar: Object.values(data.ql_mvar)[i],
                    va_hv_degree: Object.values(data.va_hv_degree)[i],
                    va_lv_degree: Object.values(data.va_lv_degree)[i],
                    vm_hv_pu: Object.values(data.vm_hv_pu)[i],
                    vm_lv_pu: Object.values(data.vm_lv_pu)[i],
                });
            }
        }
        catch (e) {
            console.error(e);
        }

        setTransformerDatas(datas);
    }

    function getDerData(data) {
        const datas = [];

        for (let i = 0; i < Object.keys(data.p_mw).length; i++) {
            datas.push({
                'Bus number': Object.values(data.bus)[i],
                'DER name': Object.values(data.name)[i],
                p_mw: Object.values(data.p_mw)[i],
                q_mvar: Object.values(data.q_mvar)[i],
            });
        }

        setDerDatas(datas);
    }

    return <>
        <Spinner loading={loading} />
        <Container fluid className="content">
            <Row>
                <Col md={7} xl={7} id="distribution">
                    <Distribution analysis={graphData} scenarioRunned={sessionStorage.runScenario} />
                </Col>
                <Col md={5} xl={5}>
                    <Summary summary={summary} export={e => {
                        if (e === 'busDataRef')
                            busDataRef.current.exportCSV();
                        else if (e === 'lineDatasRef')
                            lineDatasRef.current.exportCSV();
                        else if (e === 'transactiveEnergyDataRef')
                            transactiveEnergyDataRef.current.exportCSV();
                        else if (e === 'transformerDataRef')
                            transformerDataRef.current.exportCSV();
                        else if (e === 'derDataRef')
                            derDataRef.current.exportCSV();
                    }} />
                </Col>
            </Row>
            <Row>
                <Col md={12} xl={12}>
                    <Card title="Forecasted PV Generation(kW)" content={
                        <PVOutput data={forecasted.PVoutput} />
                    } />
                </Col>
                <Col md={12} xl={12}>
                    <Card title="Forecasted Network Electric Load (kW)" content={
                        <NetworkLoad data={forecasted.networkLoad} />
                    } />
                </Col>
            </Row>
            <Row>
                <Col md={12} xl={12}>
                    <Card title="Forecasted Slack-bus LMP($/kWh)" content={
                        <BusLMPChart data={forecasted.busLMP} />
                    } />
                </Col>
            </Row>
            <Row style={{ display: 'none' }}>
                <Col md={6} xl={6}>
                    <DataTable value={busDatas} ref={busDataRef} exportFilename="Bus Data">
                        <Column field="Bus number" header="Bus Number" />
                        <Column field="p_mw" header="P(MW)" />
                        <Column field="q_mvar" header="Q(MVAR)" />
                        <Column field="va_degree" header="Va(Deg.)" />
                        <Column field="vm_pu" header="Vm(p.u.)" />
                    </DataTable>
                </Col>
                <Col md={6} xl={6}>
                    <DataTable value={lamDatas} ref={lamDatasRef} exportFilename="LMP Data">
                        <Column field="Bus number" header="Bus Number" />
                        <Column field="lam" header="LMP($/kWh)" />
                    </DataTable>
                </Col>
                <Col md={6} xl={6}>
                    <DataTable value={lineDatas} ref={lineDatasRef} exportFilename="Line Data">
                        <Column field="Line name" header="Line Name" />
                        <Column field="i_from_ka" header="I from(kA)" />
                        <Column field="i_ka" header="I(kA)" />
                        <Column field="i_to_ka" header="I to(kA)" />
                        <Column field="loading_percent" header="Loading(%)" />
                        <Column field="p_from_mw" header="P from(MW)" />
                        <Column field="p_to_mw" header="P to(MW)" />
                        <Column field="pl_mw" header="P Loss(MW)" />
                        <Column field="q_from_mvar" header="Q from(MVAR)" />
                        <Column field="q_to_mvar" header="Q to(MVAR)" />
                        <Column field="ql_mvar" header="Q Loss(MVAR)" />
                        <Column field="va_from_degree" header="Va from(Deg.)" />
                        <Column field="va_to_degree" header="Va to(Deg.)" />
                        <Column field="vm_from_pu" header="Vm from(p.u.)" />
                    </DataTable>
                </Col>
            </Row>
            <Row style={{ display: 'none' }}>
                <Col md={6} xl={6}>
                    <DataTable value={transformerDatas} ref={transformerDataRef} exportFilename="Transformer Data">
                        <Column field="Transformer name" header="Transformer Name" />
                        <Column field="i_hv_ka" header="I HV(kA)" />
                        <Column field="i_lv_ka" header="I LV(kA)" />
                        <Column field="loading_percent" header="Loading(%)" />
                        <Column field="p_hv_mw" header="P HV(MW)" />
                        <Column field="p_lv_mw" header="P LV(MW)" />
                        <Column field="pl_mw" header="P Loss(MW)" />
                        <Column field="q_hv_mvar" header="Q HV(MVAR)" />
                        <Column field="q_lv_mvar" header="Q LV(MVAR)" />
                        <Column field="ql_mvar" header="Q Loss(MVAR)" />
                        <Column field="va_hv_degree" header="Va HV(Deg.)" />
                        <Column field="va_lv_degree" header="Va LV(Deg.)" />
                        <Column field="vm_hv_pu" header="Vm HV(p.u.)" />
                        <Column field="vm_lv_pu" header="Vm LV(p.u.)" />
                    </DataTable>
                </Col>
                <Col md={6} xl={6}>
                    <DataTable value={derDatas} ref={derDataRef} exportFilename="DER Data">
                        <Column field="Bus number" header="Bus Number" />
                        <Column field="DER name" header="DER Name" />
                        <Column field="p_mw" header="P(MW)" />
                        <Column field="q_mvar" header="Q(MVAR)" />
                    </DataTable>
                </Col>
                <Col md={6} xl={6}>
                    <DataTable value={transactiveEnergyData} ref={transactiveEnergyDataRef} exportFilename="TransactiveEnergyData">
                        <Column field="demandResponse" header="Demand Response" />
                        <Column field="grid_price" header="Grid Price ($/kWh)" />
                        <Column field="g2v_price" header="G2V Price ($/kWh)" />
                        <Column field="v2g_price" header="V2G Price ($/kWh)" />
                        <Column field="vpp_price" header="VPP Price ($/kWh)" />
                    </DataTable>
                </Col>
            </Row>
        </Container>
    </>
}

function Distribution(props) {
    const plot = createPlotlyComponent(Plotly);
    const layout = {
        width: document.getElementById("distribution")?.offsetWidth * .85,
        height: 350,
        hovermode: 'closest',
        showlegend: false,
        xaxis: { zeroline: false, hoverformat: '.2r' },
        yaxis: { zeroline: false, hoverformat: '.2r' }
    };

    useEffect(() => {
        if (props.analysis) {
            const wideColorBar = setInterval(() => {
                const colorbar = document.querySelectorAll('.colorbar');
                if (colorbar.length > 0) {
                    colorbar[0].setAttribute('transform', 'translate(20, 100)');
                    colorbar[1].setAttribute('transform', 'translate(130, 100)');
                    clearInterval(wideColorBar);
                }
            }, 100);
        }
    }, [props.analysis]);

    const dateTime = sessionStorage.dateTime ? JSON.parse(JSON.parse(JSON.stringify(sessionStorage.dateTime))) : {};

    return <Card title="Network" style={{ minHeight: '50rem' }} content={
        <>
            <span style={{ float: 'left' }}><i className="fa fa-clock-o" />{dateTime?.label}</span>
            <br />
            <span style={{ float: 'left', color: JSON.parse(props.scenarioRunned ?? 0) ? 'green' : 'red' }}>
                <i className="fa fa-info" />{
                    JSON.parse(props.scenarioRunned ?? 0) ? 'The scenario is run for this time'
                        : 'The scenario is not run for this time'
                }</span>
            {React.createElement(plot, {
                data: props.analysis,
                layout
            })}
        </>
    } />
}

function Summary(props) {
    const [summaryDatas, setSummaryDatas] = useState([]);
    const exportButtonRef = useRef(null);

    useEffect(_ => {
        const summaryDatas = [];
        const dateTime = sessionStorage.dateTime ? JSON.parse(JSON.parse(JSON.stringify(sessionStorage.dateTime))) : {};

        summaryDatas.push({
            Co2EmissionCostReduction: props.summary['Co2 Emission Cost Reduction ($)'],
            DRTotal: props.summary['DR Total (kW-h)'],
            DateAndtime: dateTime?.label,
            G2VTotal: props.summary['G2V Total (kW-h)'],
            GHGReduction: props.summary['GHG Reduction (ton)'],
            Load: props.summary['Load (kW-h)'],
            LossCostkWh: props.summary['Loss (kW-h)'],
            LossCost$: props.summary['Loss Cost ($)'],
            MaximumLineLoading: props.summary['Maximum Line Loading (%)'],
            MaximumNodalVoltage: props.summary['Maximum Nodal Voltage (p.u.)'],
            MinimumNodalVoltage: props.summary['Minimum Nodal Voltage (p.u.)'],
            PVTotal: props.summary['PV Total (kW-h)'],
            V2GTotal: props.summary['V2G Total (kW-h)']
        });

        setSummaryDatas(summaryDatas);
    }, [props.summary]);

    const items = [
        {
            label: 'Bus Data',
            icon: 'pi pi-download',
            command: (e) => props.export('busDataRef')
        },
        {
            label: 'Line Data',
            icon: 'pi pi-download',
            command: (e) => props.export('lineDatasRef')
        },
        {
            label: 'Transformer Data',
            icon: 'pi pi-download',
            command: (e) => props.export('transformerDataRef')
        },
        {
            label: 'DER Data',
            icon: 'pi pi-download',
            command: (e) => props.export('derDataRef')
        },
        {
            label: 'Transactive Energy Price Data',
            icon: 'pi pi-download',
            command: (e) => props.export('transactiveEnergyDataRef')
        },
        {
            label: 'Summary',
            icon: 'pi pi-download',
            command: (e) => exportButtonRef.current.exportCSV()
        }
    ]

    const style = { textAlign: 'center' };
    const dateTime = sessionStorage.dateTime ? JSON.parse(JSON.parse(JSON.stringify(sessionStorage.dateTime))) : {};

    return <Card title="Summary" style={{ minHeight: '50rem' }} content={
        <>
            <SplitButton className="p-button-info"
                label="Export CSV" icon="pi pi-download" model={items} />
            <Table striped responsive style={{ color: 'black' }}>
                <thead>
                    <tr>
                        <th>Date & time</th>
                        <th>Load (kWh)</th>
                        <th>PV (kWh)</th>
                        <th>V2G (kWh)</th>
                        <th>G2V (kWh)</th>
                        <th>Demand Response (kWh)</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td style={style}>{dateTime?.label}</td>
                        <td style={style}>{props.summary['Load (kW-h)']}</td>
                        <td style={style}>{props.summary['PV Total (kW-h)']}</td>
                        <td style={style}>{props.summary['V2G Total (kW-h)']}</td>
                        <td style={style}>{props.summary['G2V Total (kW-h)']}</td>
                        <td style={style}>{props.summary['DR Total (kW-h)']}</td>
                    </tr>
                </tbody>
            </Table>
            <Table striped responsive style={{ color: 'black' }}>
                <thead>
                    <tr>
                        <th>Loss<br />(kWh)</th>
                        <th>Loss Cost<br />($)</th>
                        <th>GHG Reduction (Ton)</th>
                        <th>Co2 Emission Cost Reduction ($)</th>
                        <th>Max. Line<br />Loading (%)</th>
                        <th>Min. Nodal<br />Voltage (p.u.)</th>
                        <th>Max. Nodal<br />Voltage (p.u.)</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td style={style}>{props.summary['Loss (kW-h)']}</td>
                        <td style={style}>{props.summary['Loss Cost ($)']}</td>
                        <td style={style}>{props.summary['GHG Reduction (ton)']}</td>
                        <td style={style}>{props.summary['Co2 Emission Cost Reduction ($)']}</td>
                        <td style={style}>{props.summary['Maximum Line Loading (%)']}</td>
                        <td style={style}>{props.summary['Minimum Nodal Voltage (p.u.)']}</td>
                        <td style={style}>{props.summary['Maximum Nodal Voltage (p.u.)']}</td>
                    </tr>
                </tbody>
            </Table>
            <DataTable value={summaryDatas} ref={exportButtonRef} exportFilename="Summary" style={{ display: 'none' }}>
                <Column field="DateAndtime" header="Date & time" />
                <Column field="Load" header="Load (kWh)" />
                <Column field="PVTotal" header="PV (kWh)" />
                <Column field="V2GTotal" header="V2G (kWh)" />
                <Column field="G2VTotal" header="G2V (kWh)" />
                <Column field="DRTotal" header="Demand Response (kWh)" />
                <Column field="LossCostkWh" header="Loss (kWh)" />
                <Column field="LossCost$" header="Loss Cost ($)" />
                <Column field="GHGReduction" header="GHG Reduction (Ton)" />
                <Column field="Co2EmissionCostReduction" header="Co2 Emission Cost Reduction ($)" />
                <Column field="MaximumLineLoading" header="Max. Line Loading (%)" />
                <Column field="MinimumNodalVoltage" header="Min. Nodal Voltage (p.u.)" />
                <Column field="MaximumNodalVoltage" header="Max. Nodal Voltage (p.u.)" />
            </DataTable>

        </>
    } />
}
