import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import Table from 'react-bootstrap/Table';
import { isNil } from 'lodash';

export default function DSOToVPP(props) {
  const [state, setState] = useState(
    [{
      scenario_id: JSON.parse(sessionStorage.dateTime).label,
      loadBefore: {
        ACEVSE_5: '-',
        ACEVSE_7: '-',
        ACEVSE_13: '-',
        DCEVSE_3: '-',
        DCEVSE_9: '-',
      },
      loadAfter: {
        ACEVSE_5: '-',
        ACEVSE_7: '-',
        ACEVSE_13: '-',
        DCEVSE_3: '-',
        DCEVSE_9: '-',
      },
      deltaLoad: {
        ACEVSE_5: '-',
        ACEVSE_7: '-',
        ACEVSE_13: '-',
        DCEVSE_3: '-',
        DCEVSE_9: '-',
      },
      dlmpBefore: {
        ACEVSE_5: '-',
        ACEVSE_7: '-',
        ACEVSE_13: '-',
        DCEVSE_3: '-',
        DCEVSE_9: '-',
      },
      dlmpAfter: {
        ACEVSE_5: '-',
        ACEVSE_7: '-',
        ACEVSE_13: '-',
        DCEVSE_3: '-',
        DCEVSE_9: '-',
      },
      deltaDlmp: {
        ACEVSE_5: '-',
        ACEVSE_7: '-',
        ACEVSE_13: '-',
        DCEVSE_3: '-',
        DCEVSE_9: '-',
      },
      micron: {
        commertial: '-',
        industrial: '-',
        residential: '-'
      },
      action: '-',
      landa: {
        commertial: '-',
        industrial: '-',
        residential: '-'
      }
    }]
  );

  useEffect(() => {
    if (isNil(props.data)) return;

    const dsoToEvse = props.data['DSO Offer to VPP Aggregator'];
    const evseTODso = props.data['VPP Aggregator response to DSO'];

    setState([{
      scenario_id: props.data['scenario_id'].split(' ')[0] + ' ' + props.data['scenario_id'].split(' ')[1],
      loadBefore: {
        ACEVSE_5: dsoToEvse['load before']["DR_Com 1"],
        ACEVSE_7: dsoToEvse['load before']["DR_Res 4"],
        ACEVSE_13: dsoToEvse['load before']["DR_Res 6"],
        DCEVSE_3: dsoToEvse['load before']["DR_Res 8"],
        DCEVSE_9: dsoToEvse['load before']["DR_Ind 12"],
      },
      loadAfter: {
        ACEVSE_5: dsoToEvse['load after']["DR_Com 1"],
        ACEVSE_7: dsoToEvse['load after']["DR_Res 4"],
        ACEVSE_13: dsoToEvse['load after']["DR_Res 6"],
        DCEVSE_3: dsoToEvse['load after']["DR_Res 8"],
        DCEVSE_9: dsoToEvse['load after']["DR_Ind 12"],
      },
      deltaLoad: {
        ACEVSE_5: dsoToEvse['delta load']["DR_Com 1"],
        ACEVSE_7: dsoToEvse['delta load']["DR_Res 4"],
        ACEVSE_13: dsoToEvse['delta load']["DR_Res 6"],
        DCEVSE_3: dsoToEvse['delta load']["DR_Res 8"],
        DCEVSE_9: dsoToEvse['delta load']["DR_Ind 12"],
      },
      dlmpBefore: {
        ACEVSE_5: dsoToEvse['dlmp before']["DR_Com 1"],
        ACEVSE_7: dsoToEvse['dlmp before']["DR_Res 4"],
        ACEVSE_13: dsoToEvse['dlmp before']["DR_Res 6"],
        DCEVSE_3: dsoToEvse['dlmp before']["DR_Res 8"],
        DCEVSE_9: dsoToEvse['dlmp before']["DR_Ind 12"],
      },
      dlmpAfter: {
        ACEVSE_5: dsoToEvse['dlmp after']["DR_Com 1"],
        ACEVSE_7: dsoToEvse['dlmp after']["DR_Res 4"],
        ACEVSE_13: dsoToEvse['dlmp after']["DR_Res 6"],
        DCEVSE_3: dsoToEvse['dlmp after']["DR_Res 8"],
        DCEVSE_9: dsoToEvse['dlmp after']["DR_Ind 12"],
      },
      deltaDlmp: {
        ACEVSE_5: dsoToEvse['delta dlmp']["DR_Com 1"],
        ACEVSE_7: dsoToEvse['delta dlmp']["DR_Res 4"],
        ACEVSE_13: dsoToEvse['delta dlmp']["DR_Res 6"],
        DCEVSE_3: dsoToEvse['delta dlmp']["DR_Res 8"],
        DCEVSE_9: dsoToEvse['delta dlmp']["DR_Ind 12"],
      },
      micron: {
        commertial: dsoToEvse['Discount_offer_by_DSO_to_VPP_percent']['commertial_discount_rate_offer_by_DSO_to_VPP_percent'],
        industrial: dsoToEvse['Discount_offer_by_DSO_to_VPP_percent']['industrial_discount_rate_offer_by_DSO_to_VPP_percent'],
        residential: dsoToEvse['Discount_offer_by_DSO_to_VPP_percent']['residential_discount_rate_offer_by_DSO_to_VPP_percent']
      },
      action: evseTODso['status'],
      landa: {
        commertial: evseTODso['discount_rate_offer_by_VPP_to_DSO_percent']['commertial_discount_rate_offer_by_VPP_to_DSO_percent'],
        industrial: evseTODso['discount_rate_offer_by_VPP_to_DSO_percent']['industrial_discount_rate_offer_by_VPP_to_DSO_percent'],
        residential: evseTODso['discount_rate_offer_by_VPP_to_DSO_percent']['residential_discount_rate_offer_by_VPP_to_DSO_percent']
      }
    }]);
  }, [props.data]);

  return (
    <Table striped bordered hover variant="dark">
      <thead>
        <tr>
          <th className='w-1'>Date & Time</th>
          <th colSpan={2} rowSpan={2} className='w-2'>
            Grid Status
          </th>
          <th colSpan={5} className='w-5'>
            VPP's DR commitments [kW]
            <span className='flex mt-4'>
              <th className="w-3 text-center">Com. 1</th>
              <th className="w-3 text-center">Res. 4</th>
              <th className="w-3 text-center">Res. 6</th>
              <th className="w-3 text-center">Res. 8</th>
              <th className="w-3 text-center">Ind. 12</th>
            </span>
          </th>
          <th colSpan={3} className="w-2">
            DSO to VPP discount offer [%]
            <span className='flex'>
              <th className="w-4 text-center">Com.</th>
              <th className="w-4 text-center">Res.</th>
              <th className="w-4 text-center">Ind.</th>
            </span>
          </th>
          <th>VPP action</th>
          <th colSpan={3} className="w-2">
            VPP to DSO discount counteroffer [%]
            <span className='flex'>
              <th className="w-4 text-center">Com.</th>
              <th className="w-4 text-center">Res.</th>
              <th className="w-4 text-center">Ind.</th>
            </span>
          </th>
        </tr>
      </thead>
      <tbody>
        {state.map(data =>
          <tr>
            <td className="text-center">{data.scenario_id}</td>
            <td colSpan={2}>
              <tr className="flex align-items-center">
                <td className="w-6">
                  Load (kW)
                </td>
                <td className="flex flex-column w-6">
                  <tr className="border-bottom-1 surface-border text-center">
                    congestion
                  </tr>
                  <tr className="mt-2 border-bottom-1 surface-border text-center">
                    non-congestion
                  </tr>
                  <tr className="mt-2 text-center">
                    delta load
                  </tr>
                </td>
              </tr>
              <tr className="flex mt-6 align-items-center">
                <td className="w-6">
                  DLMP ($/kWh)
                </td>
                <td className="flex flex-column w-6">
                  <tr className="border-bottom-1 surface-border text-center">
                    congestion
                  </tr>
                  <tr className="mt-2 border-bottom-1 surface-border text-center">
                    non-congestion
                  </tr>
                  <tr className="mt-2 text-center">
                    delta DLMP
                  </tr>
                </td>
              </tr>
            </td>
            <td colSpan={5}>
              <tr className='flex border-bottom-1 border-600'>
                <td className="w-3 text-center border-right-1 border-600">{data.loadBefore.ACEVSE_5}</td>
                <td className="w-3 text-center border-right-1 border-600">{data.loadBefore.ACEVSE_7}</td>
                <td className="w-3 text-center border-right-1 border-600">{data.loadBefore.ACEVSE_13}</td>
                <td className="w-3 text-center border-right-1 border-600">{data.loadBefore.DCEVSE_3}</td>
                <td className="w-3 text-center">{data.loadBefore.DCEVSE_9}</td>
              </tr>
              <tr className='flex mt-2 border-bottom-1 border-600'>
                <td className="w-3 text-center border-right-1 border-600">{data.loadAfter.ACEVSE_5}</td>
                <td className="w-3 text-center border-right-1 border-600">{data.loadAfter.ACEVSE_7}</td>
                <td className="w-3 text-center border-right-1 border-600">{data.loadAfter.ACEVSE_13}</td>
                <td className="w-3 text-center border-right-1 border-600">{data.loadAfter.DCEVSE_3}</td>
                <td className="w-3 text-center">{data.loadAfter.DCEVSE_9}</td>
              </tr>
              <tr className='flex mt-2'>
                <td className="w-3 text-center border-right-1 border-600">{data.deltaLoad.ACEVSE_5}</td>
                <td className="w-3 text-center border-right-1 border-600">{data.deltaLoad.ACEVSE_7}</td>
                <td className="w-3 text-center border-right-1 border-600">{data.deltaLoad.ACEVSE_13}</td>
                <td className="w-3 text-center border-right-1 border-600">{data.deltaLoad.DCEVSE_3}</td>
                <td className="w-3 text-center">{data.deltaLoad.DCEVSE_9}</td>
              </tr>

              <tr className='flex mt-6 border-bottom-1 border-600'>
                <td className="w-3 text-center border-right-1 border-600">{data.dlmpBefore.ACEVSE_5}</td>
                <td className="w-3 text-center border-right-1 border-600">{data.dlmpBefore.ACEVSE_7}</td>
                <td className="w-3 text-center border-right-1 border-600">{data.dlmpBefore.ACEVSE_13}</td>
                <td className="w-3 text-center border-right-1 border-600">{data.dlmpBefore.DCEVSE_3}</td>
                <td className="w-3 text-center">{data.dlmpBefore.DCEVSE_9}</td>
              </tr>
              <tr className='flex mt-2 border-bottom-1 border-600'>
                <td className="w-3 text-center border-right-1 border-600">{data.dlmpAfter.ACEVSE_5}</td>
                <td className="w-3 text-center border-right-1 border-600">{data.dlmpAfter.ACEVSE_7}</td>
                <td className="w-3 text-center border-right-1 border-600">{data.dlmpAfter.ACEVSE_13}</td>
                <td className="w-3 text-center border-right-1 border-600">{data.dlmpAfter.DCEVSE_3}</td>
                <td className="w-3 text-center">{data.dlmpAfter.DCEVSE_9}</td>
              </tr>
              <tr className='flex mt-2'>
                <td className="w-3 text-center border-right-1 border-600">{data.deltaDlmp.ACEVSE_5}</td>
                <td className="w-3 text-center border-right-1 border-600">{data.deltaDlmp.ACEVSE_7}</td>
                <td className="w-3 text-center border-right-1 border-600">{data.deltaDlmp.ACEVSE_13}</td>
                <td className="w-3 text-center border-right-1 border-600">{data.deltaDlmp.DCEVSE_3}</td>
                <td className="w-3 text-center">{data.deltaDlmp.DCEVSE_9}</td>
              </tr>
            </td>
            <td colSpan={3} className="text-center p-0">
              <tr className='flex'>
                <td className="w-4 text-center border-right-1 border-600 p-0">{data.micron.commertial}</td>
                <td className="w-4 text-center border-right-1 border-600 p-0">{data.micron.residential}</td>
                <td className="w-4 text-center p-0">{data.micron.industrial}</td>
              </tr>
            </td>
            <td className="text-center">
              {data.action}
            </td>
            <td colSpan={3} className="text-center p-0">
              <tr className='flex'>
                <td className="w-4 text-center border-right-1 border-600 p-0">{data.landa.commertial}</td>
                <td className="w-4 text-center border-right-1 border-600 p-0">{data.landa.residential}</td>
                <td className="w-4 text-center p-0">{data.landa.industrial}</td>
              </tr>
            </td>
          </tr>
        )}
      </tbody>
    </Table>
  );
}
