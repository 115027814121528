import React, { useEffect, useState } from "react";
import NVD3Chart from 'react-nvd3';
import d3 from 'd3';
import moment from 'moment';

export default function BusLMPChart(props) {
    const [data, setData] = useState([]);

    useEffect(() => {
        if (props.data === undefined) return;

        var values = [];

        for (var i = 0; i < 24; i++) {
            values.push({
                'x': new Date(moment(new Date(), 'yyyy/MM/dd').format(`yyyy-MM-DD ${i}:00:00`)),
                'y': props.data[i]
            });
        }

        values.sort((a, b) => a.x - b.x);

        let data = [
            {
                values,
                key: 'Slack-bus LMP($/kWh)',
                color: 'blue'
            }
        ];
        setData(data);
    }, [props.data]);

    return (
        <div>
            {
                React.createElement(NVD3Chart, {
                    xAxis: {
                        tickFormat: function (d) { return d3.time.format('%I %p')(new Date(d)); },
                        xScale: d3.time.scale(),
                        axisLabel: 'Time'
                    },
                    yAxis: {
                        axisLabel: 'Slack-bus LMP($/kWh)',
                        tickFormat: function (d) { return parseFloat(d).toFixed(2); }
                    },
                    type: 'lineChart',
                    interpolate: 'monotone',
                    datum: data,
                    x: 'x',
                    y: 'y',
                    height: 300

                })
            }
        </div>
    )
}
