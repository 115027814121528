import React, { useEffect, useState } from "react";
import { Card } from "components/Card/Card.jsx";
import moment from 'moment';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import DsoToEvse from './DSOToEVSE';
import DSOToVPP from "./DSOToVPP";
import EVSE_VPP_Broadcast from "./EVSE_VPP_Broadcast";
import { api } from "../api";
import { Spinner } from "Spinner";
import { toast } from 'react-toastify';

export default function EVSE() {
    const [params, setParams] = useState({});
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (sessionStorage.scenarioId && JSON.parse(sessionStorage.runScenario) && sessionStorage.run_opf) {
            setData(JSON.parse(sessionStorage.run_opf));
        }
        else {
            toast.error("No run scenario, Please run");
        }
    }, []);

    return <>
        <Spinner loading={loading} />
        <Container fluid className="content">
            <Row>
                <Col md={12} xl={12}>
                    <Card title="DSO Offer to EVSE Aggregator" content={<DsoToEvse data={data} />} />
                </Col>
            </Row>
            <Row>
                <Col md={12} xl={12}>
                    <Card title="DSO Offer to VPP Aggregator" content={<DSOToVPP data={data} />} />
                </Col>
            </Row>
            <Row>
                <Col md={6} xl={6}>
                    <Card title="Transactive Energy Cleared Price Data" content={<EVSE_VPP_Broadcast data={data} />} />
                </Col>
            </Row>
        </Container>
    </>
}
