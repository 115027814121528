import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Container from 'react-bootstrap/Container';
import { blockchainAPI } from '../api';
import { Spinner } from '../Spinner';
import '../utils';
import BlockchainLogs from "./blockchain/BlockchainLogs";
import BlockStatus from "./blockchain/BlockStatus";
import MapChart from './blockchain/MapChart';
import NodeStatus from "./blockchain/NodeStatus";
import Performance from "./blockchain/Performance";

export default function Blockchain() {
  const [loading, setLoading] = useState(true);
  const [blockchain, setBlockchain] = useState({
    active_nodes: 0,
    cpu_usage: 0,
    disabled_nodes: 0,
    latest_block_height: 0,
    latest_block_size: 0,
    latest_consensus_time: 0,
    ram_usage: 0,
    stand_by_nodes: 0,
    node_locations: [],
  });

  const { active_nodes, stand_by_nodes, disabled_nodes } = blockchain;
  const { latest_block_size, latest_block_height, latest_consensus_time } = blockchain;
  const { ram_usage, cpu_usage, node_locations } = blockchain;

  useEffect(() => {
    fetchData();
    setInterval(fetchData, 5000);
  }, []);

  function fetchData() {
    blockchainAPI.get('/Status/Current').then(res => {
      blockchain.latest_consensus_time = res.data.latest_consensus_time;
      blockchain.latest_block_size = res.data.latest_block_size;
      blockchain.latest_block_height = res.data.latest_block_height.numberformat();
      blockchain.cpu_usage = res.data.cpu_usage.toFixed(2);
      blockchain.ram_usage = res.data.ram_usage.toFixed(2);
      blockchain.disabled_nodes = res.data.disabled_nodes;
      blockchain.stand_by_nodes = res.data.stand_by_nodes;
      blockchain.active_nodes = res.data.active_nodes;
      blockchain.node_locations = res.data.node_locations;
      setBlockchain({ ...blockchain });
    });
  }

  return (
    <>
      <Spinner loading={loading} />
      <Container fluid className="content">
        <Row>
          <NodeStatus disabled={disabled_nodes} active={active_nodes} standBy={stand_by_nodes} />
          <BlockStatus latestBlockHeight={latest_block_height} latestBlockSize={latest_block_size}
            latestConsensusTime={latest_consensus_time} />
          <Performance cpuUsage={cpu_usage} ramUsage={ram_usage} onLoading={setLoading} />
        </Row>
        <Row>
          <Col lg={6}>
            <BlockchainLogs />
          </Col>
          <Col lg={6}>
            <MapChart nodeLocations={node_locations} />
          </Col>
        </Row>
      </Container>
    </>
  )
}
