import * as React from "react"

export default function Message(props) {
    return (
        <svg
            viewBox="0 0 100 80"
            width={100}
            height={80}
            x={(props.x)}
            y={(props.y - 80)}
            style={props.style}
            id={props.id}
        >
            <path
                d="M 39.6614 0 C 58.5008 0 73.773 10.6307 73.773 23.7428 C 73.773 36.8551 58.5008 47.4857 39.6614 47.4857 C 33.7549 47.4857 28.2005 46.4401 23.3558 44.602 C 13.7949 48.2014 0.7407 55 0.7407 55 C 0.7407 55 9.2528 50.181 12.7619 44.33 C 13.6584 42.8347 14.1835 41.2668 14.4842 39.7609 C 8.9365 35.5383 5.5493 29.916 5.5493 23.7428 C 5.5493 10.6307 20.8218 0 39.6614 0"
                fill={props.chg ? 'rgba(120,0,0, .8)' : 'rgba(30,144,255, .8)'}
                // fill="rgba(235, 235, 235, .9)"
                stroke={props.chg ? 'red' : 'blue'}
            />

            <text x="8" y="31"
                style={{
                    fill: 'white',
                    // fill: props.chg ? 'Crimson' : 'DarkBlue',
                    fontFamily: 'Roboto Slab',
                    fontSize: '1.8rem',
                }}>
                {props.text}
            </text>
        </svg >
    )
}