const stations = [
    { id: 'ACEVSE 13', i: 1, capacity: 4, path: 'M 778.5112 1166.7459 L 927.2586 1168.2502 L 1037.7567 1194.3705 L 1171.6293 1239.3183 L 1284.2523 1313.3684 L 1395.8028 1311.0944 L 1509.754 1311.567 L 1627.3545 1311.567 L 1728.3695 1268.7442 L 1796.3685 1191.6246 L 1796.3684 1113.0011 L 1866.4922 1113.0011 L 1866.4922 1187.3748' },
    { id: 'ACEVSE 5', i: 1, capacity: 4, path: 'M 166.8612 817.1254 L 168.8487 727.2315 L 202.7169 657.64 L 292.4095 636.0239 L 377.1724 623.0371 L 463.3031 611.346 L 542.6099 569.7976 L 564.4061 481.4218 L 559.1901 401.9792 L 523.7491 337.5726 L 456.0796 302.4555 L 337.5206 272.7135 L 285.6753 273.4548 L 284.9347 184.7349 L 335.9379 184.7349' },
    { id: 'ACEVSE 7', i: 1, capacity: 4, path: 'M 974.5909 904.0601 L 859.8314 904.2222 L 727.1363 903.9926 L 652.0399 867.9669 L 624.3943 781.0001 L 629.843 704.0882 L 666.512 643.5755 L 760.0475 624.0116 L 866.9018 619.6516 L 951.0707 611.286 L 1014.609 569.7975 L 1057.6699 517.9368 L 1060.9758 419.3291 L 1119.6415 341.2021 L 1264.9447 304.1873 L 1314.25 302.9706 L 1311.9106 213.5162 L 1261.7017 213.5162' },
    { id: 'DCEVSE 14', i: 1, capacity: 6, path: 'M 415.7678 1038.4886 L 631.3943 1036.3635 L 785.5112 1034.2386 L 908.9748 1034.471 L 1010.7572 1070.3629 L 1144.6299 1100.2926 L 1242.3781 1100.2926 L 1347.0767 1100.2926 L 1469.7493 1100.2926 L 1595.1221 978.1772 L 1597.2471 823.1254 L 1596.2427 694.5351 L 1597.2471 574.7975 L 1597.247 434.9991 L 1697.1201 367.0004 L 1750.2443 367.0003 L 1803.3685 367.0003 L 1843.7426 324.5011 L 1843.7426 273.502 L 1841.6178 213.5162' },
    { id: 'DCEVSE 3', i: 1, capacity: 6, path: 'M 305.3155 568.7975 L 202.212 586.8042 L 130.758 634.9074 L 122.639 727.39 L 124.3026 816.1253 L 219.871 868.2941 L 354.7103 866.3537 L 472.7711 866.3536 L 584.7511 903.2222 L 680.2608 934.1881 L 820.108 935.248 L 925.5437 933.5553 L 1044.4243 934.9201 L 1124.0113 926.3115 L 1193.7409 884.1056 L 1193.5284 832.7357 L 1194.7647 795.8594 L 1238.901 753.2122 L 1290.8888 752.127 L 1340.0767 751.6477' },
    { id: 'DCEVSE 9', i: 1, capacity: 6, path: 'M 1627.4016 1151.1794 L 1572.1526 1197.4672 L 1491.4165 1210.4267 L 1395.8028 1214.4056 L 1171.1154 1207.393 L 1058.8627 1158.9503 L 864.2621 1134.6305 L 740.6171 1095.7432 L 624.3943 1140.0964 L 508.394 1140.6256 L 319.8035 1136.6125 L 320.4603 1176.9371 L 318.3974 1230.8647 L 271.1691 1277.244 L 212.5789 1274.752 L 166.8612 1274.656' },
];

const pathDimantion = [
    [0, 0, 0, 0, 0, 0, 0, 0],
    [1, 0, 0, 0, 0, 1, 1, 1, 0, 0],
    [0, 0, 1, 1, 1, 0, 0, 0, 0, 1, 1, 1, 0],
    [0, 0, 0, 0, 0, 0, 0, 0, 1, 1, 1, 1, 1],
    [0, 1, 1, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0, 0, 0, 0, 0],
];

const endRotate = [
    [90],
    [90],
    [90],
    [90, 90],
    [90, 90],
    [90, 90],
];

const carColor = [1, 2, 3, 4, 5, 1, 2, 3, 4, 5, 1, 2];

export { stations, pathDimantion, carColor, endRotate }
