import React, { useState, useEffect } from "react";
import moment from 'moment';
import Chartist from 'chartist';
import ChartistGraph from "react-chartist";
import { Row, Col } from "react-bootstrap";
import { Card } from "components/Card/Card.jsx";
import { blockchainAPI } from '../../api';
import { responsiveSales } from "variables/Variables";
import { SelectButton } from 'primereact/selectbutton';
import { Progress } from 'antd';

export default function Performance(props) {
    const timeOptions = ['24h', '7d', '30d', '90d', '180d', '1Y', 'Max'];
    const [time, setTime] = useState('24h');
    const [from, setFrom] = useState(0);
    const [to, setTo] = useState(0);

    const typeOptions = ['CPU', 'RAM', 'BlockSize', 'ConsensusTime'];
    const [type, setType] = useState('CPU');

    const [performanceData, setPerformanceData] = useState({
        series: [{ data: [] }]
    });

    useEffect(() => {
        fetchData();
        setInterval(fetchData, 5000);
    }, []);

    function fetchData() {
        const dateOffset = (24 * 60 * 60);
        const to = parseInt(new Date().getTime().toString().substr(0, 10));
        const from = to - dateOffset;
        setFrom(from);
        setTo(to);

        const data = {
            "type": type,
            "from": from,
            "to": to
        };

        blockchainAPI.post('/Status/History', data).then(res => {
            performanceData.series[0].data = [];

            const blockchainRes = res.data;
            blockchainRes.forEach(element => {
                performanceData.series[0].data.push({ x: new Date(element.date), y: element.value });
            });
            setPerformanceData(performanceData);
            props.onLoading(false);
        });
    }

    const performanceOptions = {
        showPoint: false,
        axisX: {
            type: Chartist.FixedScaleAxis,
            showGrid: false,
            divisor: 10,
            labelInterpolationFnc: function (value) {
                let format = '';
                if (time === '24h')
                    format = 'HH:mm';
                else
                    format = 'MMM DD';
                return moment(value * 1000).format(format);
            }
        }
    };

    function onChange(e) {
        props.onLoading(true);

        let _from = 0;
        let _to = parseInt(new Date().getTime().toString().substr(0, 10));

        if (e.target.name === 'time') {
            const dateOffset = (24 * 60 * 60);

            switch (e.target.value) {
                case '24h':
                    _from = _to - dateOffset;
                    break;
                case '7d':
                    _from = _to - (dateOffset * 7);
                    break;
                case '30d':
                    _from = _to - (dateOffset * 30);
                    break;
                case '90d':
                    _from = _to - (dateOffset * 90);
                    break;
                case '180d':
                    _from = _to - (dateOffset * 180);
                    break;
                case '1Y':
                    _from = _to - (dateOffset * 365);
                    break;
                default:
                    break;
            }

            setFrom(_from);
            setTo(_to);
            setTime(e.target.value);
        }
        else if (e.target.name === 'type') {
            setType(e.target.value);
        }

        const data = {
            type: e.target.name === 'type' ? e.target.value : type,
            from: e.target.name === 'time' ? _from : from,
            to: e.target.name === 'time' ? _to : to,
        };

        blockchainAPI.post('/Status/History', data).then(res => {
            performanceData.series[0].data = [];

            const blockchainRes = res.data;
            blockchainRes.forEach(element => {
                performanceData.series[0].data.push({ x: new Date(element.date), y: element.value });
            });
            setPerformanceData(performanceData);
            props.onLoading(false);
        });
    }

    return (
        <Col lg={12} sm={12}>
            <Card
                title="Validator Performance"
                content={
                    <>
                        <Row>
                            <Col lg={3} sm={3} />
                            <Col lg={4} sm={4} className="mt-2">
                                <SelectButton name={'type'} value={type} options={typeOptions} onChange={onChange} />
                            </Col>
                            <Col lg={5} sm={5} className="mt-2">
                                <SelectButton name={'time'} value={time} options={timeOptions} onChange={onChange} />
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={2} sm={2} className='mt-3'>
                                <Row className="flex justify-content-center">
                                    <Progress className="" type="dashboard" percent={parseInt(props.ramUsage)}
                                        strokeColor='#F25F33' format={(percent) => `RAM ${percent}%`} />
                                </Row>
                                <Row className="flex justify-content-center">
                                    <Progress className="mx-auto" type="dashboard" percent={parseInt(props.cpuUsage)}
                                        strokeColor='#F25F33' format={(percent) => `CPU ${percent}%`} />
                                </Row>
                            </Col>
                            <Col lg={10} sm={10} className="mt-5">
                                <ChartistGraph data={performanceData} type="Line"
                                    options={performanceOptions} responsiveOptions={responsiveSales} />
                            </Col>
                        </Row>
                    </>
                }
            />
        </Col>
    )
}