
import axios from 'axios';
const blockchain_info = 'ws://3.98.97.48:9089/blockchain_info';
const blockchain_dashboard = 'ws://3.98.97.48:9089/blockchain_dashboard';
const derms_dashboard = 'ws://3.98.97.48:9089/derms_dashboard';



const api = axios.create({
    baseURL: 'https://opfcalfuse.iemssolution.com/api/dso',
});

const blockchainAPI = axios.create({
    baseURL: 'https://blockchaincalfuse.iemssolution.com',
});


export {
    blockchain_info,
    blockchain_dashboard,
    derms_dashboard,
    api,
    blockchainAPI
};
