import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import AdminLayout from "layouts/Admin";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/animate.min.css";
import "./assets/sass/light-bootstrap-dashboard-react.scss?v=1.3.0";
import "./assets/css/demo.css";
import "./assets/css/pe-icon-7-stroke.css";
import 'react-toastify/dist/ReactToastify.css';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.min.css';
import 'nvd3/build/nv.d3.css';
import { toast } from 'react-toastify';

try {
  function makeid(length) {
    let result = '';
    const characters = 'abcdefghijklmnopqrstuvwxyz';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
  }

  const id = makeid(5);
  sessionStorage.id = id;
  sessionStorage.setItem('scenarioId', JSON.stringify("2022-07-20 02:00:00" + ' ' + id));
  sessionStorage.setItem('dateTime', JSON.stringify({ label: "2022-07-20 02:00 Off-Peak", value: "2022-07-20 02:00:00" }));
  sessionStorage.setItem('runScenario', false);
  sessionStorage.removeItem('run_opf');
  sessionStorage.removeItem('scenarioLogs');

  // setDateTimeStorage(dateTimes.find(e => e.value === dateTime));

  // getAvailableDates();

  // function getAvailableDates() {
  //   const dermsDashboardWS = new WebSocket(derms_dashboard);
  //   dermsDashboardWS.onopen = function () {
  //     dermsDashboardWS.send(JSON.stringify({
  //       "jsonrpc": "2.0",
  //       "id": "23",
  //       "method": "getAvailableDates",
  //       "params": {}
  //     }));
  //   }

  //   const dateTimes = [];

  //   dermsDashboardWS.addEventListener('message', function ({ data }) {
  //     const _dateTimes = JSON.parse(data);

  //     const ms = moment.utc(
  //       moment(_dateTimes.result.endtime, "X")
  //         .diff(moment(_dateTimes.result.start_time, "X"))
  //     );

  //     const difHour = Math.floor(moment.duration(ms).asHours());

  //     for (let i = 1; i <= difHour; i++) {
  //       const dateTime = moment(_dateTimes.result.start_time, 'X')
  //         .add(i, 'hours')
  //         .utcOffset("-05:00:00")
  //         .format('YYYY/MM/DD HH:00');
  //       const utc = moment(_dateTimes.result.start_time, 'X').add(i, 'hours').format('X');
  //       dateTimes.push({ label: dateTime, value: i, utc: parseInt(utc) });
  //     }

  //     sessionStorage.setItem('dateTimes', JSON.stringify(dateTimes));
  //     sessionStorage.setItem('dateTime', JSON.stringify(dateTimes[0]));
  //   });
  // }

  ReactDOM.render(
    <BrowserRouter>
      <ToastContainer />
      <Switch>
        <Route path="/admin" render={props => <AdminLayout {...props} />} />
        <Redirect from="/" to="/admin/scenario" />
      </Switch>
    </BrowserRouter>,
    document.getElementById("root")
  );

} catch (err) {
  toast.error("Error connect to server");
  console.error(err);
}