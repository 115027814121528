import Blockchain from "views/Blockchain.jsx";
import Scenarios from "views/Scenarios.jsx";
import Network from "views/Network.jsx";
import EVSE from "views/EVSE.jsx";

const dashboardRoutes = [ 
  {
    path: "/scenario",
    name: "Scenario",
    icon: "pe-7s-display1",
    component: Scenarios,
    layout: "/admin"
  },
  {
    path: "/network",
    name: "Network",
    icon: "pe-7s-network",
    component: Network,
    layout: "/admin"
  },
  {
    path: "/blockchain",
    name: "Blockchain",
    icon: "pe-7s-box2",
    component: Blockchain,
    layout: "/admin"
  },  
  {
    path: "/evse",
    name: "Transactive Energy",
    icon: "pe-7s-graph2",
    component: EVSE,
    layout: "/admin"
  },
];

export default dashboardRoutes;
