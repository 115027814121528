import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import Table from 'react-bootstrap/Table';
import { Spinner } from "Spinner";
// import _data from '../sample_data.json';
import { isNil } from 'lodash';

export default function DSOToEVSE(props) {
  const [state, setState] = useState(
    [{
      scenario_id: JSON.parse(sessionStorage.dateTime).label,
      loadBefore: {
        ACEVSE_5: '-',
        ACEVSE_7: '-',
        ACEVSE_13: '-',
        DCEVSE_3: '-',
        DCEVSE_9: '-',
        DCEVSE_14: '-',
      },
      loadAfter: {
        ACEVSE_5: '-',
        ACEVSE_7: '-',
        ACEVSE_13: '-',
        DCEVSE_3: '-',
        DCEVSE_9: '-',
        DCEVSE_14: '-',
      },
      deltaLoad: {
        ACEVSE_5: '-',
        ACEVSE_7: '-',
        ACEVSE_13: '-',
        DCEVSE_3: '-',
        DCEVSE_9: '-',
        DCEVSE_14: '-',
      },
      dlmpBefore: {
        ACEVSE_5: '-',
        ACEVSE_7: '-',
        ACEVSE_13: '-',
        DCEVSE_3: '-',
        DCEVSE_9: '-',
        DCEVSE_14: '-',
      },
      dlmpAfter: {
        ACEVSE_5: '-',
        ACEVSE_7: '-',
        ACEVSE_13: '-',
        DCEVSE_3: '-',
        DCEVSE_9: '-',
        DCEVSE_14: '-',
      },
      deltaDlmp: {
        ACEVSE_5: '-',
        ACEVSE_7: '-',
        ACEVSE_13: '-',
        DCEVSE_3: '-',
        DCEVSE_9: '-',
        DCEVSE_14: '-',
      },
      alpha: '-',
      evseAction: '-',
      beta: '-'
    }]
  );

  useEffect(() => {
    if (isNil(props.data)) return;

    const dsoToEvse = props.data['DSO Offer to EVSE Aggregator'];
    const evseTODso = props.data['EVSE Aggregator response to DSO'];

    setState([{
      scenario_id: props.data['scenario_id'].split(' ')[0] + ' ' + props.data['scenario_id'].split(' ')[1],
      loadBefore: {
        ACEVSE_5: dsoToEvse['load before']["ACEVSE 5"],
        ACEVSE_7: dsoToEvse['load before']["ACEVSE 7"],
        ACEVSE_13: dsoToEvse['load before']["ACEVSE 13"],
        DCEVSE_3: dsoToEvse['load before']["DCEVSE 3"],
        DCEVSE_9: dsoToEvse['load before']["DCEVSE 9"],
        DCEVSE_14: dsoToEvse['load before']["DCEVSE 14"],
      },
      loadAfter: {
        ACEVSE_5: dsoToEvse['load after']["ACEVSE 5"],
        ACEVSE_7: dsoToEvse['load after']["ACEVSE 7"],
        ACEVSE_13: dsoToEvse['load after']["ACEVSE 13"],
        DCEVSE_3: dsoToEvse['load after']["DCEVSE 3"],
        DCEVSE_9: dsoToEvse['load after']["DCEVSE 9"],
        DCEVSE_14: dsoToEvse['load after']["DCEVSE 14"],
      },
      deltaLoad: {
        ACEVSE_5: dsoToEvse['delta load']["ACEVSE 5"],
        ACEVSE_7: dsoToEvse['delta load']["ACEVSE 7"],
        ACEVSE_13: dsoToEvse['delta load']["ACEVSE 13"],
        DCEVSE_3: dsoToEvse['delta load']["DCEVSE 3"],
        DCEVSE_9: dsoToEvse['delta load']["DCEVSE 9"],
        DCEVSE_14: dsoToEvse['delta load']["DCEVSE 14"],
      },
      dlmpBefore: {
        ACEVSE_5: dsoToEvse['dlmp before']["ACEVSE 5"],
        ACEVSE_7: dsoToEvse['dlmp before']["ACEVSE 7"],
        ACEVSE_13: dsoToEvse['dlmp before']["ACEVSE 13"],
        DCEVSE_3: dsoToEvse['dlmp before']["DCEVSE 3"],
        DCEVSE_9: dsoToEvse['dlmp before']["DCEVSE 9"],
        DCEVSE_14: dsoToEvse['dlmp before']["DCEVSE 14"],
      },
      dlmpAfter: {
        ACEVSE_5: dsoToEvse['dlmp after']["ACEVSE 5"],
        ACEVSE_7: dsoToEvse['dlmp after']["ACEVSE 7"],
        ACEVSE_13: dsoToEvse['dlmp after']["ACEVSE 13"],
        DCEVSE_3: dsoToEvse['dlmp after']["DCEVSE 3"],
        DCEVSE_9: dsoToEvse['dlmp after']["DCEVSE 9"],
        DCEVSE_14: dsoToEvse['dlmp after']["DCEVSE 14"],
      },
      deltaDlmp: {
        ACEVSE_5: dsoToEvse['delta dlmp']["ACEVSE 5"],
        ACEVSE_7: dsoToEvse['delta dlmp']["ACEVSE 7"],
        ACEVSE_13: dsoToEvse['delta dlmp']["ACEVSE 13"],
        DCEVSE_3: dsoToEvse['delta dlmp']["DCEVSE 3"],
        DCEVSE_9: dsoToEvse['delta dlmp']["DCEVSE 9"],
        DCEVSE_14: dsoToEvse['delta dlmp']["DCEVSE 14"],
      },
      alpha: dsoToEvse['profit_offer_by_DSO_to_EVSE_percent']['profit_offer_by_DSO_to_EVSE_percent'],
      evseAction: evseTODso['status'],
      beta: evseTODso['profit_offer_by_EVSE_to_DSO_percent']['profit_offer_by_EVSE_to_DSO_percent']
    }]);
  }, [props.data]);

  return (
    <>
      <Table striped bordered hover variant="dark">
        <thead>
          <tr>
            <th className='w-2'>Date & Time</th>
            <th colSpan={2} rowSpan={2} className='w-2'>
              Grid Status
            </th>
            <th colSpan={6} className='w-5'>
              EVSE's DR commitments [kW]
              <span className='flex mt-4'>
                <th className="w-2 text-center">AC 5</th>
                <th className="w-2 text-center">AC 7</th>
                <th className="w-2 text-center">AC 13</th>
                <th className="w-2 text-center">DC 3</th>
                <th className="w-2 text-center">DC 9</th>
                <th className="w-2 text-center">DC 14</th>
              </span>
            </th>
            <th>DSO to EVSE discount offer [%]</th>
            <th>EVSE action</th>
            <th>EVSE to DSO discount counteroffer [%]</th>
          </tr>
        </thead>
        <tbody>
          {state.map(data =>
            <tr>
              <td className="text-center">{data.scenario_id}</td>
              <td colSpan={2}>
                <tr className="flex align-items-center">
                  <td className="w-6">
                    Load (kW)
                  </td>
                  <td className="flex flex-column w-6">
                    <tr className="border-bottom-1 surface-border text-center">
                      congestion
                    </tr>
                    <tr className="mt-2 border-bottom-1 surface-border text-center">
                      non-congestion
                    </tr>
                    <tr className="mt-2 text-center">
                      delta load
                    </tr>
                  </td>
                </tr>
                <tr className="flex mt-6 align-items-center">
                  <td className="w-6">
                    DLMP ($/kWh)
                  </td>
                  <td className="flex flex-column w-6">
                    <tr className="border-bottom-1 surface-border text-center">
                      congestion
                    </tr>
                    <tr className="mt-2 border-bottom-1 surface-border text-center">
                      non-congestion
                    </tr>
                    <tr className="mt-2 text-center">
                      delta DLMP
                    </tr>
                  </td>
                </tr>
              </td>
              <td colSpan={6}>
                <tr className='flex border-bottom-1 border-600'>
                  <td className="w-2 text-center border-right-1 border-600">{data.loadBefore.ACEVSE_5}</td>
                  <td className="w-2 text-center border-right-1 border-600">{data.loadBefore.ACEVSE_7}</td>
                  <td className="w-2 text-center border-right-1 border-600">{data.loadBefore.ACEVSE_13}</td>
                  <td className="w-2 text-center border-right-1 border-600">{data.loadBefore.DCEVSE_3}</td>
                  <td className="w-2 text-center border-right-1 border-600">{data.loadBefore.DCEVSE_9}</td>
                  <td className="w-2 text-center">{data.loadBefore.DCEVSE_14}</td>
                </tr>
                <tr className='flex mt-2 border-bottom-1 border-600'>
                  <td className="w-2 text-center border-right-1 border-600">{data.loadAfter.ACEVSE_5}</td>
                  <td className="w-2 text-center border-right-1 border-600">{data.loadAfter.ACEVSE_7}</td>
                  <td className="w-2 text-center border-right-1 border-600">{data.loadAfter.ACEVSE_13}</td>
                  <td className="w-2 text-center border-right-1 border-600">{data.loadAfter.DCEVSE_3}</td>
                  <td className="w-2 text-center border-right-1 border-600">{data.loadAfter.DCEVSE_9}</td>
                  <td className="w-2 text-center">{data.loadAfter.DCEVSE_14}</td>
                </tr>
                <tr className='flex mt-2'>
                  <td className="w-2 text-center border-right-1 border-600">{data.deltaLoad.ACEVSE_5}</td>
                  <td className="w-2 text-center border-right-1 border-600">{data.deltaLoad.ACEVSE_7}</td>
                  <td className="w-2 text-center border-right-1 border-600">{data.deltaLoad.ACEVSE_13}</td>
                  <td className="w-2 text-center border-right-1 border-600">{data.deltaLoad.DCEVSE_3}</td>
                  <td className="w-2 text-center border-right-1 border-600">{data.deltaLoad.DCEVSE_9}</td>
                  <td className="w-2 text-center">{data.deltaLoad.DCEVSE_14}</td>
                </tr>

                <tr className='flex mt-6 border-bottom-1 border-600'>
                  <td className="w-2 text-center border-right-1 border-600">{data.dlmpBefore.ACEVSE_5}</td>
                  <td className="w-2 text-center border-right-1 border-600">{data.dlmpBefore.ACEVSE_7}</td>
                  <td className="w-2 text-center border-right-1 border-600">{data.dlmpBefore.ACEVSE_13}</td>
                  <td className="w-2 text-center border-right-1 border-600">{data.dlmpBefore.DCEVSE_3}</td>
                  <td className="w-2 text-center border-right-1 border-600">{data.dlmpBefore.DCEVSE_9}</td>
                  <td className="w-2 text-center">{data.dlmpBefore.DCEVSE_14}</td>
                </tr>
                <tr className='flex mt-2 border-bottom-1 border-600'>
                  <td className="w-2 text-center border-right-1 border-600">{data.dlmpAfter.ACEVSE_5}</td>
                  <td className="w-2 text-center border-right-1 border-600">{data.dlmpAfter.ACEVSE_7}</td>
                  <td className="w-2 text-center border-right-1 border-600">{data.dlmpAfter.ACEVSE_13}</td>
                  <td className="w-2 text-center border-right-1 border-600">{data.dlmpAfter.DCEVSE_3}</td>
                  <td className="w-2 text-center border-right-1 border-600">{data.dlmpAfter.DCEVSE_9}</td>
                  <td className="w-2 text-center">{data.dlmpAfter.DCEVSE_14}</td>
                </tr>
                <tr className='flex mt-2'>
                  <td className="w-2 text-center border-right-1 border-600">{data.deltaDlmp.ACEVSE_5}</td>
                  <td className="w-2 text-center border-right-1 border-600">{data.deltaDlmp.ACEVSE_7}</td>
                  <td className="w-2 text-center border-right-1 border-600">{data.deltaDlmp.ACEVSE_13}</td>
                  <td className="w-2 text-center border-right-1 border-600">{data.deltaDlmp.DCEVSE_3}</td>
                  <td className="w-2 text-center border-right-1 border-600">{data.deltaDlmp.DCEVSE_9}</td>
                  <td className="w-2 text-center">{data.deltaDlmp.DCEVSE_14}</td>
                </tr>
              </td>
              <td className="text-center">
                {data.alpha}
              </td>
              <td className="text-center">
                {data.evseAction}
              </td>
              <td className="text-center">
                {data.beta}
              </td>
            </tr>
          )}
        </tbody>
      </Table >
    </>
  );
}
