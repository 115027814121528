import React from "react";
import { Col } from "react-bootstrap";
import { StatsCard } from "components/StatsCard/StatsCard.jsx";

export default function BlockStatus(props) {
    return (
        <>
            <Col lg={3} sm={6}>
                <StatsCard
                    bigIcon={<img src="../latest_block_height.png" className="w-4 h-6rem" />}
                    statsText={'Latest Block Height'}
                    statsValue={props.latestBlockHeight}
                    statsIcon={<i className="fa fa-clock-o" />}
                    statsIconText="Updated now"
                />
            </Col>
            <Col lg={3} sm={6}>
                <StatsCard
                    bigIcon={<img src="../latest_block_size.png" className="w-4 h-6rem" />}
                    statsText="Latest Block Size"
                    statsValue={`${props.latestBlockSize} KB`}
                    statsIcon={<i className="fa fa-clock-o" />}
                    statsIconText="Updated now"
                />
            </Col>
            <Col lg={3} sm={6}>
                <StatsCard
                    bigIcon={<img src="../latest_consensus_time.png" className="w-4 h-6rem" />}
                    statsText="Latest Consensus Time"
                    statsValue={`${props.latestConsensusTime} ms`}
                    statsIcon={<i className="fa fa-clock-o" />}
                    statsIconText="Updated now"
                />
            </Col>
        </>
    )
}