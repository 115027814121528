import * as React from "react"
import { stations } from './views/Scenarios';

export default function Map(props) {
  return (
    <svg viewBox="0 0 2048 1400" {...props}>
      <image width={2048} height={1400} href='../map.jpg' />
      {props.children}
      {/* <path d={stations[0].path} style={{ stroke: 'black', fill: 'none' }}></path> */}
      {/* <path d={stations[1].path} style={{ stroke: 'black', fill: 'none' }}></path> */}
      {/* <path d={stations[2].path} style={{ stroke: 'black', fill: 'none' }}></path> */}
      {/* <path d={stations[3].path} style={{ stroke: 'black', fill: 'none' }}></path> */}
      {/* <path d={stations[4].path} style={{ stroke: 'black', fill: 'none' }}></path> */}
      {/* <path d={stations[5].path} style={{ stroke: 'black', fill: 'none' }}></path> */}
      {/* <path d={path2} style={{ stroke: 'rgb(0, 0, 0)', fill: 'none' }}></path> */}
      {/* <path d={path3} style={{ stroke: 'rgb(0, 0, 0)', fill: 'none' }}></path> */}
    </svg>
  )
}
