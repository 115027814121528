import React from "react";
import { MetroSpinner } from "react-spinners-kit";

export function Spinner(props) {
    const spinnerStyle = {
        position: 'absolute',
        width: '100%',
        height: '100%',
        backgroundColor: 'gray',
        opacity: '.8',
        zIndex: 1,
        display: props.loading ? 'block' : 'none'
    };

    const spinnerStyleInto = {
        position: 'absolute',
        zIndex: '1',
        left: '50%',
        ...props.style
    };

    return (
        <div style={spinnerStyle} >
            <div style={spinnerStyleInto} >
                <MetroSpinner size={60} color="#ff0000" loading={props.loading} />
            </div>
        </div>
    )
}