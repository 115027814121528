import React, { useState, useEffect, useMemo, useRef } from "react";
import { blockchainAPI } from '../../api';
import { Card } from "components/Card/Card.jsx";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Dialog } from "primereact/dialog";
import { Col, Row } from "react-bootstrap";
import { toast } from "react-toastify";

export default function BlockchainLogs() {
    const [blockchainLogs, setBlockchainLogs] = useState([]);
    const [showDialog, setShowDialog] = useState(false);
    const [log, setLog] = useState({});
    const [loading, setLoading] = useState(true);
    const transactionLogsRef = useRef(null);

    useEffect(() => {
        const logURL = `/Contract/Logs?scenarioId=${sessionStorage.scenarioId.replaceAll("\"", "")}&contractType=`;
        const EVSE_EVAPI = blockchainAPI.get(`${logURL}EVSE-EV`);
        const DSO_EVSEAPI = blockchainAPI.get(`${logURL}DSO-EVSE`);
        const VPP_DSOAPI = blockchainAPI.get(`${logURL}DSO-VPP`);

        Promise.all([EVSE_EVAPI, DSO_EVSEAPI, VPP_DSOAPI]).then(([EVSE_EVRes, DSO_EVSERes, VPP_DSORes]) => {
            setLoading(false);
            setBlockchainLogs([
                { type: 'EVSE EV', ...EVSE_EVRes.data },
                { type: 'DSO EVSE', ...DSO_EVSERes.data },
                { type: 'VPP DSO', ...VPP_DSORes.data }
            ]);
        }).catch(res => {
            setLoading(false);

            if (sessionStorage.run_opf)
                toast.error('Error loading blockchain logs');
        });
    }, []);

    const LogStatusBodyTemplate = (rowData) => {
        const style = {
            borderRadius: '0.5rem',
            color: 'white',
            padding: '.5rem',
            backgroundColor:
                rowData.status === 'Completed' ? 'green' :
                    rowData.status === 'Violated' ? 'red' :
                        rowData.status === 'Fulfilled' ? 'green'
                            : 'gray'
        };
        return <span style={style}>{rowData.status}</span>;
    }

    function DialogModal(props) {
        const LogIconBodyTemplate = (rowData) => {
            return <img src={`../${rowData.entity.toLowerCase()}.png`} width={50} alt={rowData.entity} className="product-image" />;
        }

        return (
            <Dialog header="Contract Body" id="contractDetails" visible={props.showDialog} modal
                appendTo={document.body} onHide={props.onHide}
                footer={<Button style={{ fontSize: '1.5rem' }} label="Close" icon="pi pi-close"
                    onClick={props.onHide} />}>
                <DataTable ref={transactionLogsRef} value={props.log.steps} rows={5} paginator
                    responsiveLayout="scroll" className="p-datatable-striped"
                    header={
                        <>
                            <Row>
                                <Col md={3} xl={3}>
                                    <Button label="Export CSV" icon="pi pi-download" style={{ fontSize: '1.5rem' }}
                                        onClick={_ => transactionLogsRef.current.exportCSV()} />
                                </Col>
                                <Col md={6} xl={6} />
                                <Col md={3} xl={3} style={{ textAlign: 'center' }}>
                                    <span style={{
                                        borderRadius: '0.5rem',
                                        color: 'white',
                                        fontSize: '1.5rem',
                                        padding: '.5rem',
                                        backgroundColor: props.log.status === 'Completed'
                                            ? 'green' : ' red'
                                    }}>{props.log.status}</span>
                                </Col>
                            </Row>
                            <Row style={{ margin: '1rem' }}>
                                <span style={{ fontSize: '1.5rem', padding: '1rem' }}>{props.log.title}</span>
                            </Row>
                        </>
                    }>
                    <Column field="entity" header="" body={LogIconBodyTemplate} style={{ width: '20%' }}></Column>
                    <Column field="title" header="Terms" style={{ width: '60%' }}></Column>
                    <Column field="status" header="Status" body={LogStatusBodyTemplate} style={{ width: '20%' }}></Column>
                </DataTable>
            </Dialog >
        )
    }

    return (
        <Card title="Smart Contracts" style={{ minHeight: '67rem' }} content={
            <>
                {useMemo(_ => {
                    return <DialogModal showDialog={showDialog} onHide={_ => setShowDialog(false)}
                        log={log} />
                }, [log, showDialog])}
                <DataTable value={blockchainLogs} rows={5} paginator className="p-datatable-striped" loading={loading}>
                    <Column field="type" header="Type" style={{ width: '20%' }}></Column>
                    <Column field="title" header="Title" style={{ width: '80%' }}></Column>
                    <Column field="status" header="Status" body={LogStatusBodyTemplate} ></Column>
                    <Column header="Steps" style={{ width: '10%' }} body={log => {
                        return <button className={"btn btn-outline-danger"} onClick={_ => {
                            setLog(log);
                            setShowDialog(true);
                        }}>{'Details'}</button>
                    }}></Column>
                </DataTable>
            </>
        } />
    );
}